import styled from 'styled-components';
import {FontWeight} from 'src/themes';
type TProps = {
  isOpened: boolean;
};
export const Wrapper = styled.div`
  transition: all 0.3s;
  background-color: ${({theme}) => theme.background.default};
  display: flex;
  justify-content: center;
  min-height: 100vh;
  padding: 0 64px;
`;
export const Title = styled.h1`
  margin-top: 0;

  font-size: 29px;
  color: ${({theme}) => theme.text.gray};
  font-weight: ${FontWeight.light};

  border-bottom: 1px solid ${({theme}) => theme.border.gray};
  padding-bottom: 16px;

  padding-bottom: 16px;
  padding-top: 32px;
  margin-bottom: 64px;
`;

export const Table = styled.div`
  margin-bottom: 32px;

  min-height: 60vh;
`;
export const ContentWrapper = styled.div<TProps>``;
export const Header = styled.li`
  display: grid;

  width: 100%;

  grid-template-columns: 6vw 11vw 14vw 9vw 8vw 9vw 9vw 13vw;
  ${({theme}) => theme.mediaQueriesMax.md} {
    grid-template-columns: 8vw 13vw 16vw 11vw 10vw 11vw 11vw 15vw;
    font-size: 14px;
  }
  ${({theme}) => theme.mediaQueriesMax.sm} {
    font-size: 9px;
  }
`;
export const Cell = styled.div`
  min-height: 100px;
  max-width: 100%;

  border-right: 1px solid ${({theme}) => theme.border.gray};
  border-bottom: 1px solid ${({theme}) => theme.border.gray};

  background-color: ${({theme}) => theme.background.dark};

  display: flex;

  justify-content: center;
  flex-direction: column;

  color: ${({theme}) => theme.text.light};

  :first-child {
    border-left: 1px solid ${({theme}) => theme.border.gray};
  }
  padding: 0 5px;
  ${({theme}) => theme.mediaQueriesMax.sm} {
    min-height: 60px;
  }
`;

export const CellContent = styled.div`
  display: flex;
  justify-content: space-around;
`;
export const SortButton = styled.button`
  width: 20px;
  height: 20px;
  background-color: red;
`;

export const Label = styled.div`
  color: ${({theme}) => theme.text.dark};
`;

export const Field = styled.div`
  width: 210px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 16px;
`;
export const Settings = styled.div`
  margin-bottom: 16px;
  display: flex;

  align-items: center;
  justify-content: space-between;
`;
export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
export const NameLabel = styled.div`
  & span {
    padding-right: 50px;
  }
`;
