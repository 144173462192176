export enum Routes {
  SIGN_IN = '/sign-in',
  MODERATOR = '/moderator',

  ALL_CLAIMS = '/',
  REJECTED_CLAIMS = '/rejected-claims',
  SUPPORTED_CLAIMS = '/supported-claims',
  WAITED_CLAIMS = '/waited-claims',
  STARTED_CLAIMS = '/started-claims',

  CLAIM_DETAIL = '/claim',

  INTERNAL = '/internal',

  PDF = '/pdf',
}
