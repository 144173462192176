import React, {useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {Routes} from 'src/routes';
import {
  Content,
  Logo,
  Wrapper,
  Title,
  Image,
  Item,
  List,
  Burger,
  Overlay,
} from './Menu.styles';
import {useData} from './useData';
import mainlogo from 'src/assets/images/Main_logo.png';
import logo from 'src/assets/images/Logo.png';
import {useDispatch} from 'react-redux';
import {authActions} from 'src/bus/auth';
import {
  AllClaimIcon,
  RejectIcon,
  InsideStatisticIcon,
  StatusIcon,
  SuccsesIcon,
  ExternalStatisticIcon,
  ModeratorIcon,
} from '../Icons';

export const Menu = () => {
  const {pathname} = useLocation();
  const {onToggleMenu, isOpened, isAdmin} = useData();

  const dispatch = useDispatch();

  return (
    <>
      <Wrapper isOpened={isOpened}>
        <Content>
          <Burger
            isOpened={isOpened}
            onClick={() => {
              onToggleMenu(!isOpened);
            }}>
            <div />
            <div />
            <div />
          </Burger>
          <Logo src={mainlogo} alt="Main logo" />
          <Title>
            <Image src={logo} alt="Logo" />
            АДМІН ПАНЕЛЬ
          </Title>

          <List>
            <Item isCurrent={pathname === Routes.ALL_CLAIMS}>
              <AllClaimIcon size="24px" />
              <Link to={Routes.ALL_CLAIMS}>Усі заявки</Link>
            </Item>

            <Item isCurrent={pathname === Routes.STARTED_CLAIMS}>
              <AllClaimIcon size="24px" />

              <Link to={Routes.STARTED_CLAIMS}>Нерозглянуті заявки</Link>
            </Item>
            <Item isCurrent={pathname === Routes.REJECTED_CLAIMS}>
              <RejectIcon size="24px" />
              <Link to={Routes.REJECTED_CLAIMS}>Відхилені заявки</Link>
            </Item>
            <Item isCurrent={pathname === Routes.SUPPORTED_CLAIMS}>
              <SuccsesIcon size="24px" /> {/*//TODO Supported*/}
              <Link to={Routes.SUPPORTED_CLAIMS}>Підтримані заявки</Link>
            </Item>
            <Item isCurrent={pathname === Routes.WAITED_CLAIMS}>
              <StatusIcon size="24px" />
              {/* //TODO Waited */}

              <Link to={Routes.WAITED_CLAIMS}>Заявки, що очікують кошти</Link>
            </Item>
            <Item isCurrent={pathname === Routes.INTERNAL}>
              <InsideStatisticIcon size="24px" />{' '}
              <Link to={Routes.INTERNAL}>Внутрішня статистика</Link>
            </Item>

            {isAdmin && (
              <Item>
                <ModeratorIcon size="24px" />

                <Link to={Routes.MODERATOR}>Призначити модератора</Link>
              </Item>
            )}

            <Item onClick={() => dispatch(authActions.logoutAsync())}>
              Вийти
            </Item>
          </List>
        </Content>
      </Wrapper>
      {isOpened && <Overlay onClick={() => onToggleMenu(false)} />}
    </>
  );
};
