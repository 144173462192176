import {SagaIterator} from 'redux-saga';
import {all, call, takeEvery} from 'redux-saga/effects';
import {types} from '../types';
import {logout, signIn} from './workers';

function* watchLogout(): SagaIterator {
  yield takeEvery(types.LOGOUT, logout);
}

function* watchSignIn(): SagaIterator {
  yield takeEvery(types.SIGN_IN, signIn);
}

export function* watchAuth(): SagaIterator {
  yield all([call(watchLogout), call(watchSignIn)]);
}
