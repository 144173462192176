import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {appActions, appSelectors} from 'src/bus/app';
import {authSelectors} from 'src/bus/auth';
import {uiSelectors} from 'src/bus/ui';
import {Menu, Redirect} from 'src/components';
import {
  Empty,
  SignIn,
  AllClaims,
  Moderator,
  ClaimDetail,
  RejectedClaims,
  SupportedClaims,
  WaitedClaims,
  StartedClaims,
  InternalStatistics,
  PdfViewer,
} from 'src/pages';

import {Routes as RouteNames} from './';
import {useData} from './useData';

export const AppRouter = () => {
  const dispatch = useDispatch();
  const initialize = useSelector(appSelectors.getInitialized);

  const logined = useSelector(authSelectors.getLogined);

  const link = useSelector(uiSelectors.getOpenLink);

  const navigate = useNavigate();
  const {pathname} = useLocation();
  const {isAdmin} = useData();

  useEffect(() => {
    if (link) {
      navigate(link);
    }
  }, [link]);

  useEffect(() => {
    if (!initialize) {
      dispatch(appActions.bootstrapAsync());
    }
  }, [initialize]);

  if (!initialize) return null;

  if (!logined) {
    return (
      <>
        <Routes>
          <Route path="*" element={<SignIn />} />
        </Routes>
      </>
    );
  }

  return (
    <>
      <Menu />
      <Routes>
        <Route path={RouteNames.ALL_CLAIMS} element={<AllClaims />} />
        <Route path={RouteNames.REJECTED_CLAIMS} element={<RejectedClaims />} />
        <Route path={RouteNames.WAITED_CLAIMS} element={<WaitedClaims />} />
        <Route
          path={RouteNames.SUPPORTED_CLAIMS}
          element={<SupportedClaims />}
        />

        <Route path={RouteNames.SIGN_IN} element={<SignIn />} />

        <Route
          path={RouteNames.MODERATOR}
          element={isAdmin ? <Moderator /> : <AllClaims />}
        />

        <Route path={RouteNames.STARTED_CLAIMS} element={<StartedClaims />} />

        <Route
          path={`${RouteNames.CLAIM_DETAIL}/:id`}
          element={<ClaimDetail />}
        />
        <Route path={RouteNames.INTERNAL} element={<InternalStatistics />} />
        <Route path={`${RouteNames.PDF}/:id`} element={<PdfViewer />} />
      </Routes>
    </>
  );
};
