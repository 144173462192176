import {Auth} from './namespace';

export enum types {
  LOGOUT = 'AUTH/LOGOUT',
  SIGN_IN = 'AUTH/SIGN_IN',
  SIGN_UP = 'AUTH/SIGN_UP',
}

export type AuthState = {
  logined: boolean;
};

//ASYNC

export type LogoutAsync = {
  type: typeof types.LOGOUT;
};

export type SignInAsync = {
  type: typeof types.SIGN_IN;
  payload: Auth.ReqSignIn;
};

export type AuthActionTypes = LogoutAsync | SignInAsync;
