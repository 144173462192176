import {
  Moderator,
  moderatorActions,
  moderatorSelectors,
} from 'src/bus/moderator';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {schema} from './validate';
import {yupResolver} from '@hookform/resolvers/yup';
import {useCallback, useEffect, useState} from 'react';
import {appActions} from 'src/bus/app';
import {format} from 'date-fns';
import {uiSelectors} from 'src/bus/ui';
import {showToast} from 'src/services/toast';

export const useData = () => {
  const dispatch = useDispatch();

  const moderators = useSelector(moderatorSelectors.getItems);
  const isLoading = useSelector(uiSelectors.getLoading('moderator'));
  const sorter = useSelector(moderatorSelectors.getSorter);

  const [date, setDate] = useState(format(new Date(), 'yyyy-LL-dd'));
  const [price, setPrice] = useState(0);

  const onCreatePrice = useCallback(() => {
    dispatch(
      appActions.updatePriceAsync({
        date,
        price,
      }),
    );
  }, [date, price]);

  const onBootstrap = useCallback(() => {
    dispatch(
      moderatorActions.fetchItemsAsync({
        sort: sorter,
      }),
    );
  }, [sorter]);

  useEffect(() => {
    onBootstrap();
  }, [onBootstrap]);

  const {
    control,
    handleSubmit,
    formState: {errors},
  } = useForm<Moderator.ReqCreateItems>({
    defaultValues: {
      email: '',
      full_name: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });
  const onSubmit = (data: Moderator.ReqCreateItems) => {
    dispatch(moderatorActions.createItemsAsync(data));
  };

  const onRemove = useCallback((id: number) => {
    dispatch(moderatorActions.removeItemAsync(id));
  }, []);

  const onChangeSorter = useCallback(
    (key: keyof Moderator.Item) => {
      dispatch(
        moderatorActions.updateSorter({
          key: 'full_name',
          type: sorter.type === 'asc' ? 'desc' : 'asc',
        }),
      );
    },
    [sorter],
  );

  useEffect(() => {
    for (let key in errors) {
      showToast({
        //@ts-ignore
        message: errors[key].message,
        type: 'error',
        hideProgressBar: true,
        position: 'bottom-right',
      });
    }
  }, [errors]);

  return {
    control,
    errors,
    handleSubmit: handleSubmit(onSubmit),
    moderators,
    onCreatePrice,
    date,
    price,
    setDate,
    setPrice,
    onRemove,
    onChangeSorter,

    isLoading,
  };
};
