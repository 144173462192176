import axios from 'src/services/axios';
import {AxiosPromise} from 'axios';
import {App} from './namespace';

export const apiApp = new (class Api {
  fetchPrice(): AxiosPromise<App.ResUpdatePrice> {
    return axios({
      url: '/costs/today',
      method: 'get',
    });
  }
  updatePrice(cost: App.ReqUpdatePrice): AxiosPromise<App.ResUpdatePrice> {
    return axios({
      url: '/costs/create',
      method: 'post',
      data: {cost},
    });
  }
})();
