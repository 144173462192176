import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {statisticActions, statisticSelectors} from 'src/bus/statistic';

export const useData = () => {
  const dispatch = useDispatch();

  const statistics = useSelector(statisticSelectors.getItems);

  const onBootstrap = useCallback(() => {
    dispatch(statisticActions.fetchItemsAsync({admin: true}));
  }, []);

  useEffect(() => {
    onBootstrap();
  }, [onBootstrap]);

  return {statistics};
};
