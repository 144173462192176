import React, {FC} from 'react';

type TProps = {
  size: string;
};

export const InsideStatisticIcon: FC<TProps> = ({size}) => {
  return (
    <svg version="1.2" viewBox="0 0 18 15" width={size} height={size}>
      <path
        fill="#092043"
        d="m0 14h18v1h-18zm2-8.6v8.6h-1v-8.6zm3 0v8.6h-1v-8.6zm9 5.4v3.2h-1v-3.2zm3 0v3.2h-1v-3.2zm0 0.2h-4v-1h4zm-12-5h-4v-1h4zm6-5h-4v-1h4zm-3-1v14h-1v-14zm3 0v14h-1v-14z"
      />
    </svg>
  );
};
