import {App} from './namespace';

export enum types {
  BOOTSTRAP = 'APP/BOOTSTRAP',

  FETCH_PRICE = 'APP/FETCH_PRICE',
  UPDATE_PRICE = 'APP/UPDATE_PRICE',

  END_FETCH_PRICE = 'APP/END_FETCH_PRICE',
}

export type AppState = {
  initialized: boolean;
  price: number;
  menu: boolean;
  per: number;
};

//ASYNC

export type BootstrapAsync = {
  type: typeof types.BOOTSTRAP;
};

export type FetchPriceAsync = {
  type: typeof types.FETCH_PRICE;
};

export type UpdatePriceAsync = {
  type: typeof types.UPDATE_PRICE;
  payload: App.ReqUpdatePrice;
};

export type AppActionTypes =
  | BootstrapAsync
  | FetchPriceAsync
  | UpdatePriceAsync;
