import React from 'react';
import {
  Cell,
  CellContent,
  ContentWrapper,
  Field,
  Header,
  Label,
  Settings,
  SortButton,
  Table,
  Title,
  Wrapper,
  LoaderWrapper,
  NameLabel,
} from './WaitedClaims.styles';
import {ClaimCard} from 'src/components/Cards/ClaimCard';
import {useData} from './useData';
import {useNavigate} from 'react-router-dom';
import {Routes} from 'src/routes';
import {
  Container,
  FilterForm,
  PaginateScale,
  SelectBox,
  Loader,
  Button,
} from 'src/components';

import {useSelector} from 'react-redux';
import {appSelectors} from 'src/bus/app';
import arrows from 'src/assets/images/arrow.png';
import ENV from 'src/configs';

export const WaitedClaims = () => {
  const {
    claims,
    page,
    onChangeSorter,
    onChangePer,
    per,
    onLoadByPage,
    onSelect,
    totalPage,
    onChangeFilter,
    isLoading,
    count,
    name,
  } = useData();

  const navigate = useNavigate();
  const isOpened = useSelector(appSelectors.getMenu);

  return (
    <Wrapper>
      <Container>
        <ContentWrapper isOpened={isOpened}>
          <Title>Заявки, що очікують кошти</Title>

          <Settings>
            <Field>
              <Label>Показувати по </Label>
              <SelectBox
                onChange={({target}) => onChangePer(+target.value)}
                width="80px"
                height="32px">
                <option selected={per === 5}>5</option>
                <option selected={per === 10}>10</option>
                <option selected={per === 25}>25</option>
                <option selected={per === 50}>50</option>
                <option selected={per === 100}>100</option>
                <option selected={per === 250}>250</option>
                <option selected={per === 500}>500</option>
              </SelectBox>
            </Field>
            <NameLabel>
              <span>
                <b>Модератор:</b> {name}
              </span>
              <Button
                onClick={() =>
                  window.open(`${ENV.BASE_URL}/statistics/file_all`)
                }>
                Завантажити реєстр
              </Button>
            </NameLabel>
          </Settings>
          <FilterForm onSubmit={onChangeFilter} />

          <Table>
            <Header>
              <Cell role="button" onClick={() => onChangeSorter('id')}>
                <CellContent>
                  ID
                  <img src={arrows} />
                </CellContent>
              </Cell>
              <Cell role="button" onClick={() => onChangeSorter('created_at')}>
                <CellContent>
                  Дата, час
                  <img src={arrows} />
                </CellContent>
              </Cell>
              <Cell role="button" onClick={() => onChangeSorter('full_name')}>
                <CellContent>
                  Піб особи
                  <img src={arrows} />
                </CellContent>
              </Cell>
              <Cell role="button" onClick={() => onChangeSorter('ipn')}>
                <CellContent>
                  ІНН
                  <img src={arrows} />
                </CellContent>
              </Cell>
              <Cell role="button" onClick={() => onChangeSorter('certificate_participant_hostilities')}>
                <CellContent>
                УБД
                  <img src={arrows} />
                </CellContent>
              </Cell>
              <Cell role="button" onClick={() => onChangeSorter('status')}>
                <CellContent>
                  Статус
                  <img src={arrows} />
                </CellContent>
              </Cell>
              <Cell role="button" onClick={() => onChangeSorter('email_sent')}>
                <CellContent>
                  Лист
                  <img src={arrows} />
                </CellContent>
              </Cell>
              <Cell
                role="button"
                onClick={() => onChangeSorter('moderator_full_name')}>
                <CellContent>
                  Модератор
                  <img src={arrows} />
                </CellContent>
              </Cell>
            </Header>
            {!isLoading ? (
              claims.map((claim, index) => (
                <ClaimCard
                  onSelect={() => onSelect(claim.id, !claim.selected)}
                  isGray={index % 2 === 1}
                  key={`claim-${claim.id}`}
                  claim={claim}
                  onSearch={onChangeFilter}
                />
              ))
            ) : (
              <LoaderWrapper>
                <Loader size="100px" />
              </LoaderWrapper>
            )}
          </Table>
          <PaginateScale
            current={page}
            onClick={onLoadByPage}
            count={count}
            total_page={totalPage}
            per={per}
            length={claims.length}
          />
        </ContentWrapper>
      </Container>
    </Wrapper>
  );
};
