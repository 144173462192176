import {format} from 'date-fns';
import {uk} from 'date-fns/locale';
import React from 'react';
import {Container, Loader} from 'src/components';

import {
  ButtonsWrapper,
  EInfo,
  Footer,
  Header,
  HeaderWrapper,
  Item,
  Label,
  List,
  LoaderWrapper,
  Wrapper,
} from './PdfViewer.styles';
import {useData} from './useData';

export const PdfViewer = () => {
  const {detail, isLoading} = useData();

  return !isLoading && detail ? (
    <Wrapper>
      <Container>
        <Header>
          <div></div>
          <HeaderWrapper>
            <Label>
              Виконавчому директору бюджетної установи "Український ветеранський
              фонд"
            </Label>

            <Label>від {detail?.full_name}</Label>
            <Label>
              який (яка) проживає за адресою:
              <br />
              {detail?.actual_address?.region_name}
              {', '}
              {detail?.actual_address?.full_name},{' '}
              {detail?.actual_address?.street}
              {', '}
              {detail?.actual_address?.house} Індекс:
              {detail?.actual_address?.zip_code} <br />
              +38{detail?.tel}
              <br />
              {detail?.email}
              <br />
              <br />
            </Label>
          </HeaderWrapper>
        </Header>
        <Label>
          Прошу надати одноразову фінансову допомогу на відшкодування витрат,
          пов'язаних з закупівлею предметів, товарів, для ведення,
          підприємнецької, або незалежної професійної діяльності, а саме: <br />
          {detail?.description}
          <br />У сумі {detail?.price} грн
        </Label>
        <Label>
          Грошову допомогу прошу перерахувати за такими реквізитами: <br />
          {detail?.bank_name} <br />
          {detail?.iban}
        </Label>
        <Label>До заяви додаю копії таких документів:</Label>
        <Label>
          <List>
            <Item>Документи, що посвідчує особу громадянина України;</Item>
            <Item>
              Документи про набуття статусу підприємця фізичною особою (для
              фізичних осіб-підприємція) або Документи про взяття на облік
              самозайнятої особи;
            </Item>
            <Item>
              Документа, що підтверджує один із статусів ветерана війни, особи,
              яка має особливі заслуги перед Батьківщиною, постраждалого
              учасника Революції Гідності, члена сім’ї загиблого (померлого)
              Захисника чи Захисниці України або належність до членів сім’ї
              ветерана;
            </Item>
            <Item>
              Документи, що п ідтвержує взяття особи на облік внутрішньо
              переміщеної особи;
            </Item>
            <Item>
              Документи про понесаення фактичних витрат, пов'язаних із
              здійсненням підприємницької або незалежної професійної діяльності
              самозайнятою особою, які можуть бути відшкодовані за рахунок
              Фонду;
            </Item>
          </List>
        </Label>
        <Footer>
          {format(new Date(detail?.created_at), 'dd.LL.yyyy kk:mm ', {
            locale: uk,
          })}
          <EInfo>
            <span>Дані власника ЕЦП:</span>
            <br />
            {detail.full_name?.toUpperCase()}
            <br />
            {detail.ipn || detail.passport}
            <br />
          </EInfo>
        </Footer>
      </Container>
    </Wrapper>
  ) : (
    <LoaderWrapper>
      <Loader size="100px" />
    </LoaderWrapper>
  );
};
