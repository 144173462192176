import {SagaIterator} from 'redux-saga';
import {all, call, takeEvery} from 'redux-saga/effects';
import {types} from '../types';

import {bootstrap, fetchPrice, updatePrice} from './workers';

function* watchBootstrap(): SagaIterator {
  yield takeEvery(types.BOOTSTRAP, bootstrap);
}

function* watchFetchPrice(): SagaIterator {
  yield takeEvery(types.FETCH_PRICE, fetchPrice);
}

function* watchUpdatePrice(): SagaIterator {
  yield takeEvery(types.UPDATE_PRICE, updatePrice);
}

export function* watchApp(): SagaIterator {
  yield all([
    call(watchBootstrap),
    call(watchFetchPrice),
    call(watchUpdatePrice),
  ]);
}
