import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {User} from './namespace';

import {UserActionTypes, UserState, types} from './types';

const initialState: UserState = {
  detail: null,
};

const slice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    saveDetail: (state: UserState, action: PayloadAction<User.Item>) => {
      state.detail = action.payload;
    },
  },
});

export default slice.reducer;

export const userActions = {
  ...slice.actions,
  fetchDetailAsync: (): UserActionTypes => ({
    type: types.FETCH_DETAIL,
  }),
};
