import {all, put, call} from 'redux-saga/effects';
import {uiActions} from 'src/bus/ui';
import {SagaIterator} from 'redux-saga';
import {RemoveItemAsync} from '../../types';
import {apiModerator} from '../../api';
import {moderatorActions} from '../../';

export function* removeItem(action: RemoveItemAsync): SagaIterator {
  try {
    yield put(uiActions.toggleLoader({name: 'moderator', loading: true}));

    const response = yield call(apiModerator.removeItem, action.payload);

    yield put(moderatorActions.removeItem(action.payload));
  } catch (e) {
    console.log(`error remove moderator item worker ${e}`);
  } finally {
    yield put(uiActions.toggleLoader({name: 'moderator', loading: false}));
  }
}
