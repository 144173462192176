import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Moderator} from '.';

import {
  ModeratorActionTypes,
  ModeratorState,
  types,
  CreateItemsAsync,
} from './types';

const initialState: ModeratorState = {
  items: [],

  sorter: {
    key: 'full_name',
    type: 'asc',
  },
};

const slice = createSlice({
  name: 'Moderator',
  initialState,
  reducers: {
    saveItems: (
      state: ModeratorState,
      action: PayloadAction<Moderator.ResFetchItems>,
    ) => {
      state.items = action.payload.users;
    },

    removeItem: (state: ModeratorState, action: PayloadAction<number>) => {
      state.items = state.items.filter(({id}) => id !== action.payload);
    },

    updateSorter: (
      state: ModeratorState,
      action: PayloadAction<Moderator.Sorter>,
    ) => {
      state.sorter = action.payload;
    },
  },
});

export default slice.reducer;

export const moderatorActions = {
  ...slice.actions,
  fetchItemsAsync: (
    payload: Moderator.ReqFetchItems,
  ): ModeratorActionTypes => ({
    type: types.FETCH_ITEMS,
    payload,
  }),
  createItemsAsync: (
    payload: Moderator.ReqCreateItems,
  ): ModeratorActionTypes => ({
    type: types.CREATE_ITEMS,
    payload,
  }),
  removeItemAsync: (payload: number): ModeratorActionTypes => ({
    type: types.REMOVE_ITEM,
    payload,
  }),
};
