import {FontWeight} from 'src/themes';
import styled from 'styled-components';
type TProps = {
  isOpened: boolean;
};
export const Wrapper = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  overflow-x: hidden;
  flex-direction: column;
  background-color: ${({theme}) => theme.background.default};

  overflow-x: visible;
`;
export const Title = styled.h1<TProps>`
  padding-top: 55px;
  font-size: 29px;
  color: ${({theme}) => theme.text.gray};
  font-weight: ${FontWeight.light};
  width: 100%;
  padding-bottom: 16px;
  transition: all 0.3s;
  border-bottom: 1px solid ${({theme}) => theme.border.gray};
`;
export const Content = styled.div<TProps>`
  transition: all 0.3s;
  background-color: ${({theme}) => theme.background.light};

  padding: 32px 0;
`;

export const Form = styled.form`
  margin: 32px 0;
`;

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  margin-bottom: 32px;
`;

export const Field = styled.div`
  display: grid;

  grid-template-columns: 1fr 1fr;

  column-gap: 16px;

  margin: 32px 0;
`;

export const Label = styled.label`
  color: ${({theme}) => theme.text.default};
`;
export const MainLabel = styled.label`
  color: ${({theme}) => theme.text.dark};
  font-weight: ${FontWeight.light};
`;
export const Section = styled.section`
  border-bottom: 1px solid ${({theme}) => theme.border.gray};

  padding: 0 64px;

  margin-bottom: 16px;
`;

export const Footer = styled.div`
  padding: 0 64px;
`;

export const Images = styled.div`
  width: 100%;

  margin: 32px 0;
`;

export const ImageWrapper = styled.div`
  display: flex;

  flex-wrap: wrap;

  align-items: center;
`;
export const Image = styled.img`
  max-width: 256px;
  max-height: 256px;

  margin-right: 16px;

  cursor: pointer;
`;

export const Error = styled.span`
  color: ${({theme}) => theme.text.danger};

  margin-left: 8px;
`;
export const Success = styled.span`
  color: ${({theme}) => theme.text.success};
  margin-left: 8px;
`;
export const LetterFooter = styled.div`
  display: grid;

  grid-template-columns: 300px 300px 1fr 250px;
  column-gap: 16px;

  margin-top: 32px;

  & > ${Label} {
    display: flex;

    align-items: center;
  }
`;

export const LoadingWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ReasonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
