import {Claim} from './namespace';

export enum types {
  FETCH_ITEMS = 'CLAIM/FETCH_ITEMS',
  FETCH_DETAIL = 'CLAIM/FETCH_DETAIL',
  FETCH_PREVIEW_ITEM = 'CLAIM/FETCH_PREVIEW_ITEM',

  SEND_MAIL = 'CLAIM/SEND_MAIL',

  UPDATE_ITEM = 'CLAIM/UPDATE_ITEM',
  CREATE_ITEM = 'CLAIM/CREATE_ITEM',

  UPDATE_REASON = 'CLAIM/UPDATE_REASON',

  END_FETCH_ITEM = 'CLAIM/END_FETCH_ITEM',
  END_FETCH_DETAIL = 'CLAIM/END_FETCH_DETAIL',
}

export type ClaimState = {
  detail: Claim.Detail | null;
  items: Claim.Item[];

  detail_preview: Claim.ReqCreateItem | null;

  sorter: Claim.Sorter | null;
  filter: Claim.Filter | null;

  current_page: number;
  total_page: number;
  count: number;
};

export type FetchItemsAsync = {
  type: typeof types.FETCH_ITEMS;
  payload: Claim.ReqFetchItems;
};

export type FetchDetailAsync = {
  type: typeof types.FETCH_DETAIL;
  payload: number;
};
export type FetchPreviewItemAsync = {
  type: typeof types.FETCH_PREVIEW_ITEM;
  payload: number;
};
export type UpdateItemAsync = {
  type: typeof types.UPDATE_ITEM;
  payload: Claim.ReqUpdateItem;
};

export type UpdateReasonAsync = {
  type: typeof types.UPDATE_REASON;
  payload: Claim.ReqUpdateReason;
};

export type CreateItemAsync = {
  type: typeof types.CREATE_ITEM;
  payload: Claim.ReqCreateItem;
};

export type SendMailAsync = {
  type: typeof types.SEND_MAIL;
  payload: Claim.ReqSendMail;
};

export type ClaimActionTypes =
  | CreateItemAsync
  | UpdateItemAsync
  | SendMailAsync
  | FetchItemsAsync
  | FetchDetailAsync
  | UpdateReasonAsync
  | FetchPreviewItemAsync;
