import {SagaIterator} from 'redux-saga';
import {all, call, takeEvery} from 'redux-saga/effects';
import {types} from '../types';
import {
  createItem,
  fetchDetail,
  fetchItems,
  sendMail,
  updateItem,
  updateReason,
  fetchPreviewItem,
} from './workers';

function* watchCreateItem(): SagaIterator {
  yield takeEvery(types.CREATE_ITEM, createItem);
}

function* watchFetchItems(): SagaIterator {
  yield takeEvery(types.FETCH_ITEMS, fetchItems);
}

function* watchFetchDetail(): SagaIterator {
  yield takeEvery(types.FETCH_DETAIL, fetchDetail);
}

function* watchUpdateItem(): SagaIterator {
  yield takeEvery(types.UPDATE_ITEM, updateItem);
}

function* watchSendMail(): SagaIterator {
  yield takeEvery(types.SEND_MAIL, sendMail);
}

function* watchUpdateReason(): SagaIterator {
  yield takeEvery(types.UPDATE_REASON, updateReason);
}

function* watchFetchPreviewItem(): SagaIterator {
  yield takeEvery(types.FETCH_PREVIEW_ITEM, fetchPreviewItem);
}

export function* watchClaim(): SagaIterator {
  yield all([
    call(watchCreateItem),
    call(watchFetchItems),
    call(watchFetchDetail),
    call(watchUpdateItem),
    call(watchSendMail),
    call(watchUpdateReason),
    call(watchFetchPreviewItem),
  ]);
}
