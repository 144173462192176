import {all, put, call} from 'redux-saga/effects';
import {uiActions} from 'src/bus/ui';
import {SagaIterator} from 'redux-saga';
import {apiApp} from '../../api';
import {UpdatePriceAsync} from '../../types';
import {appActions} from '../../slice';

export function* updatePrice(action: UpdatePriceAsync): SagaIterator {
  try {
    yield put(uiActions.toggleLoader({name: 'price', loading: true}));

    const resonse = yield call(apiApp.updatePrice, action.payload);

    yield put(appActions.fetchPriceAsync());
  } catch (e) {
    console.log(`error update price  worker ${e}`);
  } finally {
    yield put(uiActions.toggleLoader({name: 'price', loading: false}));
  }
}
