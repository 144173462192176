import {AxiosPromise} from 'axios';
import axios from 'src/services/axios';

export const apiUser = new (class Api {
  fetchDetail(): AxiosPromise {
    return axios({
      url: '/users',
    });
  }
})();
