import {FontWeight} from 'src/themes';
import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: ${({theme}) => theme.background.default};

  min-height: 100vh;
`;
export const List = styled.ul`
  display: flex;

  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
`;
export const Title = styled.h1`
  margin-top: 0;

  font-size: 29px;
  color: ${({theme}) => theme.text.gray};
  font-weight: ${FontWeight.light};

  border-bottom: 1px solid ${({theme}) => theme.border.gray};
  padding-bottom: 16px;
  padding-top: 32px;
  margin-bottom: 64px;
`;
