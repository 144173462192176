import {AxiosPromise} from 'axios';
import {Moderator} from '.';

import axios from 'src/services/axios';
import qs from 'qs';

export const apiModerator = new (class Api {
  fetchItems(
    params: Moderator.ReqFetchItems,
  ): AxiosPromise<Moderator.ResFetchItems> {
    return axios({
      url: '/users',
      method: 'get',
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
  }
  createItems(user: Moderator.ReqCreateItems): AxiosPromise {
    return axios({
      url: '/users',
      method: 'post',
      data: {
        user,
      },
    });
  }

  removeItem(id: number): AxiosPromise {
    return axios({url: `/users/${id}`, method: 'DELETE'});
  }
})();
