import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Claim} from './namespace';

import {ClaimActionTypes, ClaimState, types} from './types';

const initialState: ClaimState = {
  detail: null,
  detail_preview: null,
  items: [],

  sorter: {
    key: 'id',
    type: 'asc',
  },
  filter: null,

  current_page: 0,
  total_page: 0,
  count: 0,
};

const slice = createSlice({
  initialState,
  name: 'Claim',
  reducers: {
    saveItems: (
      state: ClaimState,
      action: PayloadAction<Claim.ResFetchItems>,
    ) => {
      state.items = action.payload.claims;

      state.current_page = action.payload.page;
      state.total_page = action.payload.total_page;
      state.count = action.payload.count;
    },

    clearItems: (state: ClaimState) => {
      state.items = [];
      state.total_page = 0;
      state.current_page = 1;
      state.count = 0;
    },

    saveSorter: (state: ClaimState, action: PayloadAction<Claim.Sorter>) => {
      state.sorter = action.payload;
    },

    saveDetail: (state: ClaimState, action: PayloadAction<Claim.Detail>) => {
      state.detail = action.payload;
    },

    clearDetail: (state: ClaimState) => {
      state.detail = null;
    },

    clearSorter: (state: ClaimState) => {
      state.sorter = {
        key: 'id',
        type: 'asc',
      };
    },
    savePreviewDetail: (
      state: ClaimState,
      action: PayloadAction<Claim.ReqCreateItem>,
    ) => {
      state.detail_preview = action.payload;
    },
    clearPreviewDettail: (state: ClaimState) => {
      state.detail_preview = null;
    },
    updateItem: (
      state: ClaimState,
      action: PayloadAction<Claim.ReqUpdateItem>,
    ) => {
      const index = state.items.findIndex(({id}) => id === action.payload.id);

      if (index !== -1) {
        if (action.payload.selected) {
          state.items[index].selected = action.payload.selected;
        }
      }
    },

    changeFilter: (state: ClaimState, action: PayloadAction<Claim.Filter>) => {
      state.filter = action.payload;
    },
    clearFilter: (state: ClaimState) => {
      state.filter = null;
    },
  },
});

export default slice.reducer;

export const claimActions = {
  ...slice.actions,
  createItemAsync: (payload: Claim.ReqCreateItem): ClaimActionTypes => ({
    type: types.CREATE_ITEM,
    payload,
  }),

  updateItemAsync: (payload: Claim.ReqUpdateItem): ClaimActionTypes => ({
    type: types.UPDATE_ITEM,
    payload,
  }),

  sendMailAsync: (payload: Claim.ReqSendMail): ClaimActionTypes => ({
    type: types.SEND_MAIL,
    payload,
  }),

  fetchItemsAsync: (payload: Claim.ReqFetchItems): ClaimActionTypes => ({
    type: types.FETCH_ITEMS,
    payload,
  }),
  fetchDetailAsync: (payload: number): ClaimActionTypes => ({
    type: types.FETCH_DETAIL,
    payload,
  }),
  fetchPreviewItemAsync: (payload: number): ClaimActionTypes => ({
    type: types.FETCH_PREVIEW_ITEM,
    payload,
  }),
  updateReasonAsync: (payload: Claim.ReqUpdateReason): ClaimActionTypes => ({
    type: types.UPDATE_REASON,
    payload,
  }),
};
