import {userSelectors} from 'src/bus/user';

import {appActions, appSelectors} from 'src/bus/app';
import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

export const useData = () => {
  const dispatch = useDispatch();
  const isOpened = useSelector(appSelectors.getMenu);

  const price = useSelector(appSelectors.getPrice);

  const onToggleMenu = useCallback((isOpened: boolean) => {
    dispatch(appActions.toggleMenu(isOpened));
  }, []);
  const isAdmin = useSelector(userSelectors.getRole);
  return {onToggleMenu, isOpened, price, isAdmin};
};
