import React, {FC, useMemo} from 'react';
import {Controller, useForm} from 'react-hook-form';

import {Button} from '../';

import {
  ContentWrapper,
  Form,
  FormButton,
  Input,
  Label,
  Scale,
  Wrapper,
} from './PaginateScale.styles';

type TProps = {
  current: number;
  total_page: number;
  count: number;
  per: number;
  onClick: (value: number) => any;
  length: number;
};

const PIECE_COUNT = 5;

const extractPiece = (
  arr: number[],
  current: number,
  total: number,
): number[] => {
  if (current < 3) return arr.slice(0, 4);

  if (total - current < 3) return arr.slice(total - 4);

  return arr.slice(current - 2, current + 2);
};

export const PaginateScale: FC<TProps> = ({
  current,
  onClick,
  total_page,
  count,
  length,
  per,
}) => {
  const position = useMemo(() => {
    return {
      from: per * (current - 1) + 1,
      to: per * (current - 1) + length,
      total: count,
    };
  }, [length, per, count, current]);

  const pages = useMemo(() => {
    return Array.from(
      {length: total_page < current ? current : total_page},
      (_, i) => i + 1,
    );
  }, [total_page, current]);

  const {control, handleSubmit} = useForm<{page: number}>({
    defaultValues: {
      page: 1,
    },
  });

  const renderButtons = useMemo(() => {
    const pagesCount = pages.length;

    if (pagesCount <= PIECE_COUNT) {
      if (!pagesCount || pagesCount === 1) return null;

      return pages.map((page) => (
        <Button
          size="small"
          margin={{right: '8px'}}
          width="36px"
          key={`paginate-${page}`}
          onClick={() => onClick(page)}
          color={page === current ? 'default' : 'light'}>
          {page}
        </Button>
      ));
    }

    const firstPiece = extractPiece(pages, current, total_page);

    return (
      <>
        {current > 2 && (
          <>
            <Button
              size="small"
              margin={{right: '8px'}}
              width="58px"
              onClick={() => onClick(current - 1)}
              color={'light'}>
              Назад
            </Button>
            <span>...</span>
          </>
        )}

        {firstPiece.map((page, index) => (
          <Button
            size="small"
            margin={{right: '8px', left: !index && page !== 1 ? '8px' : '0px'}}
            width="36px"
            key={`paginate-${page}`}
            onClick={() => onClick(page)}
            color={page === current ? 'default' : 'light'}>
            {page}
          </Button>
        ))}
        {total_page - current > 2 && (
          <>
            <span>...</span>
            <Button
              size="small"
              margin={{left: '8px'}}
              width="52px"
              onClick={() => onClick(current + 1)}
              color={'light'}>
              Далі
            </Button>
          </>
        )}
      </>
    );
  }, [current, pages]);

  return (
    <Wrapper>
      <Label>
        <span>{position.from}</span> - <span>{position.to}</span> Всього:{' '}
        <span>{position.total}</span>
      </Label>
      <Scale>{renderButtons}</Scale>
      <Form onSubmit={handleSubmit(({page}) => onClick(page || 1))}>
        <Label>
          Всього: <span>{total_page}</span>
        </Label>
        <ContentWrapper>
          <Controller
            name="page"
            control={control}
            render={({field: {name, onBlur, onChange, value}}) => (
              <Input
                value={value}
                name={name}
                type="text"
                onBlur={onBlur}
                onChange={(e) =>
                  onChange({
                    ...e,
                    target: {
                      ...e.target,
                      value: +e.target.value.replace(/\D/g, ''),
                    },
                  })
                }
              />
            )}
          />
          <FormButton>Відкрити</FormButton>
        </ContentWrapper>
      </Form>
    </Wrapper>
  );
};
