import styled from 'styled-components';
import {FontWeight} from 'src/themes';
type TProps = {
  isOpened: boolean;
};
export const Wrapper = styled.div`
  background-color: ${({theme}) => theme.background.default};
  display: flex;
  justify-content: center;
  min-height: 100vh;
  padding: 0 64px;
`;
export const Title = styled.h1`
  padding-top: 55px;
  font-size: 29px;
  color: ${({theme}) => theme.text.gray};
  font-weight: ${FontWeight.light};
  margin: 0;
  padding-bottom: 16px;
  padding-top: 32px;
  margin-bottom: 64px;

  border-bottom: 1px solid ${({theme}) => theme.border.gray};
`;

export const Header = styled.li`
  width: 100%;

  display: grid;
`;
export const Table = styled.div``;
export const ContentWrapper = styled.div<TProps>``;
export const Label = styled.div``;
export const Menu = styled.div`
  padding: 20px 0;
  align-items: flex-start;
`;
export const Form = styled.form`
  display: grid;

  grid-template-columns: repeat(4, 1fr);
  column-gap: 16px;

  margin-top: 24px;
`;
export const MenuItem = styled.div`
  display: grid;

  grid-template-columns: repeat(3, 1fr);
  column-gap: 16px;
`;
export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const Cell = styled.div`
  min-height: 80px;
  max-width: 100%;
  border-right: 1px solid ${({theme}) => theme.border.gray};
  border-bottom: 1px solid ${({theme}) => theme.border.gray};
  background-color: ${({theme}) => theme.background.dark};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${({theme}) => theme.text.light};
  padding-left: 30px;
`;
export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
export const Arrows = styled.img`
  padding-left: 10px;
`;
