import React, {FC} from 'react';
import {useTheme} from 'src/hooks';
import {TColorsIcon} from 'src/themes';

type TProps = {
  size: string;
};

export const SuccsesIcon: FC<TProps> = ({size}) => {
  return (
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 23"
      width="20"
      height="23">
      <path
        fill="#092043"
        d="m0 5h1v18h-1zm0 0h14v1h-14zm13 0h1v18h-1zm-13 17h14v1h-14zm2-15h1v6h-1zm0 6h4v1h-4zm3-6h1v6h-1zm-3 0h4v1h-4zm5 0h5v1h-5zm0 2h5v1h-5zm0 2h5v1h-5zm0 2h5v1h-5zm-2 2h7v1h-7zm0 2h7v1h-7zm0 2h7v1h-7zm-3-4h2v1h-2zm0 2h2v1h-2zm0 2h2v1h-2z"
      />
      <path
        fill="#0aa520"
        d="m10 2.4l0.8-0.2 4.1 4.5-0.8 0.3zm4.5 4.3l-1.1-0.5 5.6-6.2 1 0.4zm-5.5-4.3l0.8-0.3 4.1 4.5-0.8 0.3zm4.5 4.3l-1-0.4 5.5-6.3 1 0.4z"
      />
    </svg>
  );
};
