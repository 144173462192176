import React, {useMemo} from 'react';
import {Controller} from 'react-hook-form';
import {AreaField, Button, Container, Loader, SelectBox} from 'src/components';

import {
  Content,
  Field,
  Footer,
  Form,
  Label,
  Section,
  StatusWrapper,
  Wrapper,
  Images,
  ImageWrapper,
  Image,
  Title,
  MainLabel,
  Error,
  LetterFooter,
  Success,
  LoadingWrapper,
  ReasonWrapper,
} from './ClaimDetail.styles';
import {useData} from './useData';

import ENV from 'src/configs';
import {format} from 'date-fns';
import {useNavigate} from 'react-router-dom';
import {Routes} from 'src/routes';

const STATUSES = [
  '',
  'Неповний пакет документів',
  'Нецільова аудиторія',
  'Документи прийняті, кошти надіслані',
  'Особа у черзі, очікуються додаткові кошти',
];

export const ClaimDetail = () => {
  const {
    control,
    detail,
    errors,
    handleSubmit,
    setStatus,
    status,
    isOpened,
    price,
    onChangeStatus,
    onSaveNote,
    isLoading,
    reason,
    setReason,
    onUpdateReason,
  } = useData();
  const navigate = useNavigate();
  const renderOptions = useMemo(() => {
    if (detail) {
      return STATUSES.map((status, index) => {
        if (index === 3 && !price) {
          return null;
        }

        if (!detail.status && status) {
          return (
            <option value={index} key={status} hidden={!status}>
              {status}
            </option>
          );
        }

        if (detail.status === STATUSES[4] && status === STATUSES[3]) {
          return (
            <option value={index} key={status}>
              {status}
            </option>
          );
        }

        return null;
      }).filter((item) => item);
    }

    return [];
  }, [detail, price]) as JSX.Element[];

  const renderModerator = useMemo(() => {
    if (detail) {
      if (!detail.status) {
        return <Label>Модератора не назначено</Label>;
      }

      if (detail.moderator_full_name) {
        return <Label>Модератор: {detail.moderator_full_name}</Label>;
      }

      if (!detail.moderator_full_name) {
        return <Error>Модератора видалено</Error>;
      }
    }

    return null;
  }, [detail]);

  if (isLoading) {
    return (
      <Wrapper>
        <Container>
          <LoadingWrapper>
            <Loader size="128px" />
          </LoadingWrapper>
        </Container>
      </Wrapper>
    );
  }

  if (!detail) {
    return null;
  }

  return (
    <Wrapper>
      <Container>
        <Title isOpened={isOpened}>Заявка № {detail.id}</Title>
        <Content isOpened={isOpened}>
          <Section>
            <Field>
              <MainLabel>Прізвище, ім'я та по батькові:</MainLabel>
              <Label>{detail.full_name}</Label>
            </Field>
            <Field>
              <MainLabel>Стать:</MainLabel>
              <Label>{detail.sex ? 'Чоловік' : 'Жінка'}</Label>
            </Field>

            <Field>
              <MainLabel>Дата народження:</MainLabel>
              <Label>{detail.birthday}</Label>
            </Field>

            <Field>
              <MainLabel>РНОКПП або серія та номер паспорта:</MainLabel>
              <Label>{detail.ipn || detail.passport}</Label>
            </Field>

            <Field>
              <MainLabel>Адреса реєстрації:</MainLabel>
              <Label>
                {detail.registration_address.region_name}
                {', '}
                {detail.registration_address.district_name}
                {', '}
                {detail.registration_address.community}
                {', '}
                {detail.registration_address.full_name}
                {', '}
                {detail.registration_address.street}
                {', '}
                {detail.registration_address.house}, Індекс{' '}
                {detail.registration_address.zip_code}
              </Label>
            </Field>

            <Field>
              <MainLabel>Адреса фактичного перебування:</MainLabel>
              <Label>
                {detail.actual_address.region_name}
                {', '}
                {detail.actual_address.district_name}
                {', '}
                {detail.actual_address.community}
                {', '}
                {detail.actual_address.full_name},{' '}
                {detail.actual_address.street}
                {', '}
                {detail.actual_address.house} Індекс{', '}
                {detail.actual_address.zip_code}
              </Label>
            </Field>
          </Section>

          <Section>
            <Field>
              <MainLabel>Форма здійснення господарської діяльності:</MainLabel>
              <Label>
                {detail.economic_activity
                  ? 'Фізична особа підприємець'
                  : 'Інша категорія самозайнятої особи'}
              </Label>
            </Field>

            <Field>
              <MainLabel>Опис здійснюваної господарської діяльності:</MainLabel>
              <Label>{detail.economic_description}</Label>
            </Field>

            <Field>
              <MainLabel>Назва банку:</MainLabel>
              <Label>{detail.bank_name}</Label>
            </Field>

            <Field>
              <MainLabel>Рахунок у форматі IBAN:</MainLabel>
              <Label>{detail.iban}</Label>
            </Field>

            <Field>
              <MainLabel>Адреса для листування:</MainLabel>
              <Label>{detail.correspondence_address}</Label>
            </Field>

            <Field>
              <MainLabel>Email:</MainLabel>
              <Label>
                <a
                  href={`mailto:${detail.email}`}
                  target="_blank"
                  rel="noreferrer">
                  {detail.email}
                </a>
              </Label>
            </Field>

            <Field>
              <MainLabel>Контактний телефон:</MainLabel>
              <Label>{detail.tel}</Label>
            </Field>
          </Section>

          <Section>
            <Field>
              <MainLabel>Категорія осіб:</MainLabel>
              <Label> Категорія {detail.category ? 'A' : 'Б'}</Label>
            </Field>

            <Field>
              <MainLabel>Родинні зв’язки:</MainLabel>
              <Label>{detail.family_ties}</Label>
            </Field>

            <Field>
              <MainLabel>Прізвище, ім'я та по батькові власника посвідчення Учасника бойових дій:</MainLabel>
              <Label>{detail.full_name_participant_hostilities}</Label>
            </Field>

            <Field>
              <MainLabel>Cерія та номера посвідчення Учасника бойових дій:</MainLabel>
              <Label>{detail.certificate_participant_hostilities}</Label>
            </Field>

            <Field>
              <MainLabel>На що саме потрібні кошти:</MainLabel>
              <Label>{detail.description}</Label>
            </Field>

            <Field>
              <MainLabel>Сума грошей:</MainLabel>
              <Label>{detail.price} грн</Label>
            </Field>
          </Section>

          <Section>
            <Images>
              {' '}
              <MainLabel>
                Документ, що посвідчує особу громадянина України:
              </MainLabel>
              <ImageWrapper>
                {detail.citizen_document.map((img, index) => (
                  <Image
                    onClick={() =>
                      window.open(`${ENV.BASE_URL}${img.url}`, '_blank')
                    }
                    key={`citizen_document-${img.thumb}-${index}`}
                    src={`${ENV.BASE_URL}${img.thumb}`}
                  />
                ))}
              </ImageWrapper>
            </Images>
            <Images>
              {' '}
              <MainLabel>
                Документ про набуття статусу підприємця фізичною особою (для
                фізичних осіб-підприємців) АБО Документ про взяття на облік
                самозайнятої особи:
              </MainLabel>
              <ImageWrapper>
                {detail.employed_document.map((img, index) => (
                  <Image
                    onClick={() =>
                      window.open(`${ENV.BASE_URL}${img.url}`, '_blank')
                    }
                    key={`employed_document-${img.thumb}-${index}`}
                    src={`${ENV.BASE_URL}${img.thumb}`}
                  />
                ))}
              </ImageWrapper>
            </Images>
            <Images>
              <MainLabel>
                Документа, що підтверджує один із статусів ветерана війни,
                особи, яка має особливі заслуги перед Батьківщиною,
                постраждалого учасника Революції Гідності, члена сім’ї загиблого
                (померлого) Захисника чи Захисниці України або належність до
                членів сім’ї ветерана;
              </MainLabel>

              <ImageWrapper>
                {detail.army_costs_document.map((img, index) => (
                  <Image
                    onClick={() =>
                      window.open(`${ENV.BASE_URL}${img.url}`, '_blank')
                    }
                    key={`army_costs_document-${img.thumb}-${index}`}
                    src={`${ENV.BASE_URL}${img.thumb}`}
                  />
                ))}
              </ImageWrapper>
            </Images>
            <Images>
              <MainLabel>
                Документ, що підтверджує взяття особи на облік внутрішньо
                переміщеної особи:
              </MainLabel>

              <ImageWrapper>
                {detail.internally_document.map((img, index) => (
                  <Image
                    onClick={() =>
                      window.open(`${ENV.BASE_URL}${img.url}`, '_blank')
                    }
                    key={`internally_document-${img.thumb}-${index}`}
                    src={`${ENV.BASE_URL}${img.thumb}`}
                  />
                ))}
              </ImageWrapper>
            </Images>
            <Images>
              <MainLabel>
                Документа про понесення фактичних витрат, пов’язаних із
                здійсненням підприємницької або незалежної професійної
                діяльності самозайнятою особою, які можуть бути відшкодовані за
                рахунок Фонду:
              </MainLabel>

              <ImageWrapper>
                {detail.actual_costs_document.map((img, index) => (
                  <Image
                    onClick={() =>
                      window.open(`${ENV.BASE_URL}${img.url}`, '_blank')
                    }
                    key={`actual_costs_document-${img.thumb}-${index}`}
                    src={`${ENV.BASE_URL}${img.thumb}`}
                  />
                ))}
              </ImageWrapper>
            </Images>

            {!!detail.bank_account_document.length && (
              <Images>
                <MainLabel>
                  Довідка про відкриття банківського рахунку з актуальними
                  банківськими реквізитами:
                </MainLabel>

                <ImageWrapper>
                  {detail.bank_account_document.map((img, index) => (
                    <Image
                      onClick={() =>
                        window.open(`${ENV.BASE_URL}${img.url}`, '_blank')
                      }
                      key={`bank_account_document-${img.thumb}-${index}`}
                      src={`${ENV.BASE_URL}${img.thumb}`}
                    />
                  ))}
                </ImageWrapper>
              </Images>
            )}
          </Section>

          <Footer>
            <Label>Статус заявки</Label>
            <StatusWrapper>
              {!!renderOptions.length ? (
                <>
                  <SelectBox
                    defaultValue="Оберіть статус"
                    onChange={({target}) => {
                      setStatus(+target.value);
                    }}>
                    <option hidden>Оберіть статус</option>
                    {renderOptions}
                  </SelectBox>
                  <Button width="200px" onClick={onChangeStatus}>
                    Змінити статус
                  </Button>
                </>
              ) : (
                <Label>{detail.status}</Label>
              )}
              {renderModerator}
            </StatusWrapper>

            <ReasonWrapper>
              Коментар від модератора
              <AreaField
                width="100%"
                value={reason}
                onChange={({target}) => setReason(target.value)}
                name="reason"
                error={''}
                margin={{top: '16px'}}
              />
              <Button
                onClick={onUpdateReason}
                type="button"
                margin={{top: '16px'}}>
                Зберегти
              </Button>
              <Button
                onClick={() => navigate(`${Routes.PDF}/${detail.id}`)}
                type="button"
                margin={{top: '16px'}}>
                Завантажити або роздрукувати файл
              </Button>
            </ReasonWrapper>

            <Form onSubmit={handleSubmit}>
              <MainLabel>Текст листа</MainLabel>
              <Controller
                control={control}
                name="note"
                render={({field: {name, onBlur, onChange, value}}) => (
                  <AreaField
                    width="100%"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    name={name}
                    error={errors[name]?.message}
                    margin={{top: '16px'}}
                  />
                )}
              />
              <LetterFooter>
                <Button width="100%">Відправити лист</Button>
                <Button width="100%" onClick={onSaveNote} type="button">
                  Зберегти
                </Button>
                <Label>
                  {detail.email_sent &&
                    `Дата і час:
                    ${format(
                      new Date(detail.email_sent_at),
                      'dd.LL.yyyy kk:mm ',
                    )}
               `}
                </Label>

                <Label>
                  Лист
                  {detail.email_sent ? (
                    <Success> відправлено</Success>
                  ) : (
                    <Error> не відправлено</Error>
                  )}
                </Label>
              </LetterFooter>
            </Form>
          </Footer>
        </Content>
      </Container>
    </Wrapper>
  );
};
