import React, {FC, InputHTMLAttributes, ReactNode, useMemo} from 'react';
import {FieldError} from 'react-hook-form';

import {
  Wrapper,
  Input,
  ErrorText,
  InputWrapper,
  IconWrapper,
} from './FilledField.styles';

type TProps = InputHTMLAttributes<HTMLInputElement> & {
  width?: string;
  margin?: {
    top?: string;
    bottom?: string;
    left?: string;
    right?: string;
  };
  error?: string;
  dark?: boolean;

  errorEmpty?: boolean;

  rightIcon?: ReactNode;
};

export const FilledField: FC<TProps> = ({
  width = '300px',
  margin,
  error,
  dark,
  errorEmpty = false,
  rightIcon,
  ...props
}) => {
  const margins = useMemo(
    () => ({
      bottom: margin?.bottom || '0',
      top: margin?.top || '0',
      right: margin?.right || '0',
      left: margin?.left || '0',
    }),
    [margin],
  );

  console.log(rightIcon);

  return (
    <Wrapper width={width} margin={margins}>
      <InputWrapper>
        <Input {...props} dark={dark} />

        {rightIcon && <IconWrapper>{rightIcon}</IconWrapper>}
      </InputWrapper>
      {!errorEmpty && <ErrorText>{error || ''}</ErrorText>}
    </Wrapper>
  );
};
