import React from 'react';
import {Container} from 'src/components';
import {StatisticCard} from 'src/components/Cards/StatisticCard';

import {List, Wrapper, Title} from './InternalStatistics.styles';
import {useData} from './useData';

import ENV from 'src/configs';

export const InternalStatistics = () => {
  const {statistics} = useData();

  return (
    <Wrapper>
      <Container>
        <Title>Внутрішня статистика</Title>
        <List>
          {statistics.map((statistic) => (
            <StatisticCard
              key={`stat-${statistic.picture}`}
              statistic={statistic}
              onClick={() => window.open(`${ENV.BASE_URL}${statistic.url}`)}
              hasPointer
            />
          ))}
        </List>
      </Container>
    </Wrapper>
  );
};
