import {authActions} from 'src/bus/auth';
import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Claim, claimActions, claimSelectors} from 'src/bus/claim';
import {appActions, appSelectors} from 'src/bus/app';
import {uiSelectors} from 'src/bus/ui';
import {userSelectors} from 'src/bus/user';

export const useData = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(uiSelectors.getLoading('claim'));
  const claims = useSelector(claimSelectors.getItems);
  const page = useSelector(claimSelectors.getPage);
  const totlaPage = useSelector(claimSelectors.getTotalPage);
  const menu = useSelector(appSelectors.getMenu);
  const name = useSelector(userSelectors.getName);

  const sorter = useSelector(claimSelectors.getSorter);
  const filter = useSelector(claimSelectors.getFilter);

  const per = useSelector(appSelectors.getPer);

  const count = useSelector(claimSelectors.getCount);

  const onBootstrap = useCallback(() => {
    dispatch(
      claimActions.fetchItemsAsync({
        page: 1,
        per_page: per,
        status: 'all',
        sort: sorter || {
          key: 'id',
          type: 'asc',
        },
        filter: filter || {},
      }),
    );
  }, [sorter, per, filter]);

  const onChangeSorter = useCallback(
    (key: keyof Claim.Item) => {
      if (sorter && key === sorter.key) {
        if (sorter.type === 'asc') {
          dispatch(
            claimActions.saveSorter({
              ...sorter,
              type: 'desc',
            }),
          );
        } else {
          if (sorter.type === 'desc') {
            dispatch(claimActions.clearSorter());
          }
        }
      } else {
        dispatch(
          claimActions.saveSorter({
            key,
            type: 'asc',
          }),
        );
      }
    },
    [sorter],
  );

  const onLoadByPage = useCallback(
    (page: number) => {
      dispatch(
        claimActions.fetchItemsAsync({
          page,
          per_page: per,
          status: 'all',
          sort: sorter || {
            key: 'id',
            type: 'asc',
          },
          filter: filter || {},
        }),
      );
    },
    [sorter, per, filter],
  );

  useEffect(() => {
    onBootstrap();
  }, [onBootstrap]);
  useEffect(() => {
    return () => {
      dispatch(claimActions.clearFilter());
      dispatch(claimActions.clearSorter());
    };
  }, []);

  const onSelect = useCallback((id: number, selected: boolean) => {
    dispatch(claimActions.updateItemAsync({id, selected}));
  }, []);

  const onChangeFilter = useCallback((filter: Claim.Filter | null) => {
    if (filter) {
      dispatch(claimActions.changeFilter(filter));
    } else {
      dispatch(claimActions.clearFilter());
    }
  }, []);

  const onChangePer = useCallback((per: number) => {
    dispatch(appActions.changePer(per));
  }, []);

  return {
    claims,
    page,
    onChangeSorter,
    sorter,
    onChangePer,
    per,
    onLoadByPage,
    onSelect,
    totlaPage,
    onChangeFilter,
    menu,
    isLoading,
    count,
    name,
  };
};
