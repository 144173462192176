import React, {FC} from 'react';
import {useTheme} from 'src/hooks';
import {TColorsIcon} from 'src/themes';

type TProps = {
  size: string;
};

export const StatusIcon: FC<TProps> = ({size}) => {
  return (
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      width="18"
      height="18">
      <path
        fill="#092043"
        d="m0 0h1v18h-1zm0 0h14v1h-14zm13 0h1v18h-1zm-13 17h14v1h-14zm2-15h1v6h-1zm0 6h4v1h-4zm3-6h1v6h-1zm-3 0h4v1h-4zm5 0h5v1h-5zm0 2h5v1h-5zm0 2h5v1h-5zm0 2h5v1h-5zm-2 2h7v1h-7zm0 2h7v1h-7zm0 2h7v1h-7zm11-14h2v8h-2zm0 9h2v2h-2zm-14 1h2v1h-2zm0 2h2v1h-2zm0 2h2v1h-2z"
      />
    </svg>
  );
};
