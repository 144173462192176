import React, {useMemo} from 'react';
import {
  Button,
  Container,
  DateField,
  FilledField,
  ModeratorCard,
  Loader,
} from 'src/components';
import {useData} from './useData';
import {useSelector} from 'react-redux';

import {
  Wrapper,
  Table,
  Title,
  ContentWrapper,
  Header,
  Cell,
  Menu,
  Label,
  InputWrapper,
  MenuItem,
  Form,
  LoaderWrapper,
  Arrows,
} from './Moderator.styles';
import {Controller} from 'react-hook-form';
import {format} from 'date-fns';
import {appSelectors} from 'src/bus/app';
import arrows from 'src/assets/images/arrow.png';

const DAY_MILISECONDS = 86400000;

export const Moderator = () => {
  const {
    control,
    errors,
    handleSubmit,
    moderators,
    onCreatePrice,
    date,
    price,
    setDate,
    setPrice,
    onRemove,
    onChangeSorter,
    isLoading,
  } = useData();
  const isOpened = useSelector(appSelectors.getMenu);

  const max = useMemo(() => {
    const date = new Date();

    const now = date.getMilliseconds();
    const next = now + DAY_MILISECONDS;

    return date.setMilliseconds(next);
  }, []);

  return (
    <Wrapper>
      <Container>
        <ContentWrapper isOpened={isOpened}>
          <Title>Призначити модератора </Title>
          <Menu>
            <MenuItem>
              <InputWrapper>
                <Label>Дата</Label>
                <DateField
                  value={date}
                  onChange={({target}) => setDate(target.value)}
                  type="date"
                  min={format(new Date(), 'yyyy-LL-dd')}
                  max={format(max, 'yyyy-LL-dd')}
                  errorEmpty
                />
              </InputWrapper>
              <InputWrapper>
                <Label>Сума на день</Label>
                <FilledField
                  width="100%"
                  value={price}
                  onChange={({target}) =>
                    setPrice(+(target.value.replace(/\D/g, '') || 0))
                  }
                  errorEmpty
                />
              </InputWrapper>
              <Button
                color="green"
                width="100%"
                margin={{top: '5%'}}
                onClick={onCreatePrice}>
                Зберегти суму
              </Button>
            </MenuItem>
            <Form onSubmit={handleSubmit}>
              <InputWrapper>
                <Label>ПІБ модератора</Label>

                <Controller
                  control={control}
                  name="full_name"
                  render={({field: {onBlur, onChange, name, value}}) => (
                    <FilledField
                      width="100%"
                      value={value}
                      placeholder="П.І.Б."
                      type="moderator_full_name"
                      onBlur={onBlur}
                      onChange={onChange}
                      error={errors[name]?.message}
                      errorEmpty
                    />
                  )}
                />
              </InputWrapper>
              <InputWrapper>
                <Label>Пошта модератора</Label>

                <Controller
                  control={control}
                  name="email"
                  render={({field: {onBlur, onChange, name, value}}) => (
                    <FilledField
                      width="100%"
                      value={value}
                      placeholder="E-mail"
                      type="text"
                      onBlur={onBlur}
                      onChange={onChange}
                      error={errors[name]?.message}
                      errorEmpty
                    />
                  )}
                />
              </InputWrapper>
              <InputWrapper>
                <Label>Пароль</Label>
                <Controller
                  control={control}
                  name="password"
                  render={({field: {onBlur, onChange, name, value}}) => (
                    <FilledField
                      width="100%"
                      value={value}
                      placeholder="Пароль"
                      type="password"
                      onBlur={onBlur}
                      onChange={onChange}
                      error={errors[name]?.message}
                      errorEmpty
                    />
                  )}
                />
              </InputWrapper>
              <Button color="green" margin={{top: '10%'}}>
                Додати модератора
              </Button>
            </Form>
          </Menu>
          <Table>
            <Header onClick={() => onChangeSorter('full_name')}>
              <Cell>
                Модератори <Arrows src={arrows} />
              </Cell>
            </Header>
            {!isLoading ? (
              moderators.map((moderator, index) => (
                <ModeratorCard
                  isGray={index % 2 === 1}
                  key={`moderator-${moderator.id}`}
                  moderator={moderator}
                  onRemove={() => onRemove(moderator.id)}
                />
              ))
            ) : (
              <LoaderWrapper>
                <Loader size="100px" />
              </LoaderWrapper>
            )}
          </Table>
        </ContentWrapper>
      </Container>
    </Wrapper>
  );
};
