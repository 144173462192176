import React from 'react';
import {Button, Container, FilledField, SelectBox} from 'src/components';

import {Wrapper, Form, Title} from './SignIn.styles';
import {useData} from './useData';
import {Controller} from 'react-hook-form';
import {useNavigate, Link} from 'react-router-dom';
import {Routes} from 'src/routes';

export const SignIn = () => {
  const {control, handleSubmit, errors, onLogout} = useData();

  return (
    <Wrapper>
      <Container>
        <Form onSubmit={handleSubmit}>
          <Title>Вхід в систему</Title>
          <Controller
            control={control}
            name="email"
            render={({field: {onBlur, onChange, name, value}}) => (
              <FilledField
                value={value}
                placeholder="E-mail"
                type="text"
                onBlur={onBlur}
                onChange={onChange}
                error={errors[name]?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="password"
            render={({field: {onBlur, onChange, name, value}}) => (
              <FilledField
                value={value}
                placeholder="Пароль"
                type="password"
                onBlur={onBlur}
                onChange={onChange}
                margin={{bottom: '20px'}}
                error={errors[name]?.message}
              />
            )}
          />

          <Button>ВХІД</Button>
        </Form>
      </Container>
    </Wrapper>
  );
};
