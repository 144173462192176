import {all, call, put} from 'redux-saga/effects';

import {AxiosResponse} from 'axios';
import {uiActions} from 'src/bus/ui/slice';
import {SignInAsync} from 'src/bus/auth/types';
import {apiAuth} from 'src/bus/auth/api';
import {Auth, authActions} from 'src/bus/auth';
import {SagaIterator} from 'redux-saga';
import {appActions} from 'src/bus/app';
export function* signIn(action: SignInAsync): SagaIterator {
  try {
    yield all([
      put(uiActions.toggleLoader({name: 'sign_in', loading: true})),
      put(uiActions.clearError('sign_in')),
    ]);

    const response: AxiosResponse<Auth.ResSignIn> = yield call(apiAuth.signIn, {
      ...action.payload,
    });

    if (response.data) {
      yield put(appActions.fetchPriceAsync());
      yield put(authActions.toggleLogined(true));
    }
  } catch (e) {
    console.log(`error sign in worker ${e}`);
  } finally {
    yield put(uiActions.toggleLoader({name: 'sign_in', loading: false}));
  }
}
