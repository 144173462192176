import {appActions} from './../../slice';
import {all, put, take} from 'redux-saga/effects';
import {SagaIterator} from 'redux-saga';

import {types} from '../../types';

export function* bootstrap(): SagaIterator {
  try {
    yield all([put(appActions.fetchPriceAsync())]);

    yield all([take(types.END_FETCH_PRICE)]);
  } catch (e) {
    console.error(`error app bootstrap worker ${e}`);
  } finally {
    yield put(appActions.toggleInitialized(true));
  }
}
