import {FontWeight} from './../../themes/context';
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 32px 64px;
`;

export const Header = styled.div`
  display: grid;

  grid-template-columns: 1fr 1fr;
  /* column-gap: 32px; */
`;
export const HeaderWrapper = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: space-between;
`;

export const Label = styled.div`
  margin-bottom: 16px;
`;

export const List = styled.ul``;

export const Item = styled.li``;

export const Footer = styled.div`
  margin-top: 64px;

  display: flex;
  justify-content: space-between;
`;
export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const EInfo = styled.div`
  line-height: 24px;

  & > span {
    font-weight: ${FontWeight.medium};
  }
`;
