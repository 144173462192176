import styled from 'styled-components';
import {FontWeight} from 'src/themes';

export const Wrapper = styled.section`
  display: flex;

  justify-content: center;
  align-items: center;

  min-height: 100vh;

  background-color: ${({theme}) => theme.background.default};
`;
export const Form = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 370px;
  min-height: 335px;
  border-radius: 5px;
  background-color: ${({theme}) => theme.background.light};
  box-shadow: 1px 4px 29px 14px rgba(34, 60, 80, 0.24);
`;
export const Title = styled.h1`
  margin: 40px 0 20px 0;
  font-size: 23px;
  font-weight: ${FontWeight.light};
  ${({theme}) => theme.text.gray}
`;
