import {FC, useRef} from 'react';
import {Moderator} from 'src/bus/moderator';
import {Button} from 'src/components/Core';

import {Cell, Wrapper, CellContent} from './ModeratorCard.styles';

type TProps = {
  moderator: Moderator.Item;
  isGray: boolean;
  onRemove: () => any;
};

export const ModeratorCard: FC<TProps> = ({moderator, isGray, onRemove}) => {
  return (
    <Wrapper>
      <Cell isGray={isGray}>
        <CellContent>{moderator.full_name}</CellContent>
      </Cell>
      <Cell isGray={isGray}>
        <Button width="80%" color="danger" onClick={onRemove}>
          Видалити
        </Button>
      </Cell>
    </Wrapper>
  );
};
