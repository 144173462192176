import {all, call, takeEvery} from 'redux-saga/effects';
import {SagaIterator} from 'redux-saga';
import {types} from '../types';
import {fetchItems, createItems, removeItem} from './workers';

function* watchFetchItems(): SagaIterator {
  yield takeEvery(types.FETCH_ITEMS, fetchItems);
}
function* watchCreateItems(): SagaIterator {
  yield takeEvery(types.CREATE_ITEMS, createItems);
}
function* watchRemoveItem(): SagaIterator {
  yield takeEvery(types.REMOVE_ITEM, removeItem);
}
export function* watchModerator(): SagaIterator {
  yield all([
    call(watchFetchItems),
    call(watchCreateItems),
    call(watchRemoveItem),
  ]);
}
