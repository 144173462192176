import {all, call} from 'redux-saga/effects';

//watchers
import {watchApp} from 'src/bus/app/saga/watchers';
import {watchAuth} from 'src/bus/auth/saga/watchers';
import {watchClaim} from 'src/bus/claim/saga/watchers';
import {watchLocation} from 'src/bus/location/saga/watchers';
import {watchModerator} from 'src/bus/moderator/saga/watchers';
import {watchStatistic} from 'src/bus/statistic/saga/watchers';
import {watchUser} from 'src/bus/user/saga/watchers';

function* rootSaga() {
  try {
    yield all([
      call(watchApp),
      call(watchAuth),
      call(watchLocation),
      call(watchClaim),
      call(watchModerator),
      call(watchUser),
      call(watchStatistic),
    ]);
  } catch (error) {
    console.error('⛔️ error', error);
  }
}

export default rootSaga;
