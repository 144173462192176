import React, {FC} from 'react';
import {useTheme} from 'src/hooks';
import {TColorsIcon} from 'src/themes';

type TProps = {
  size: string;
};

export const AllClaimIcon: FC<TProps> = ({size}) => {
  return (
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 20"
      width="16"
      height="20">
      <style></style>
      <path
        fill="#092043"
        d="m0 0h1v18h-1zm0 0h14v1h-14zm13 0h1v18h-1zm-13 17h14v1h-14zm2 0h1v3h-1zm11-15h3v1h-3zm2 0h1v18h-1zm-13 17h14v1h-14zm0-17h1v6h-1zm0 6h4v1h-4zm3-6h1v6h-1zm-3 0h4v1h-4zm5 0h5v1h-5zm0 2h5v1h-5zm0 2h5v1h-5zm0 2h5v1h-5zm-2 2h7v1h-7zm0 2h7v1h-7zm0 2h7v1h-7zm-3-4h2v1h-2zm0 2h2v1h-2zm0 2h2v1h-2z"
      />
    </svg>
  );
};
