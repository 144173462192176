import {appSelectors} from 'src/bus/app';
import {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {claimActions, claimSelectors, Claim} from 'src/bus/claim';
import {uiSelectors} from 'src/bus/ui';

type TParams = {
  id: string;
};

export const useData = () => {
  const dispatch = useDispatch();

  const {id} = useParams<TParams>();

  const detail = useSelector(claimSelectors.getDetail);
  const isOpened = useSelector(appSelectors.getMenu);
  const price = useSelector(appSelectors.getPrice);

  const isLoading = useSelector(uiSelectors.getLoading('claim'));

  const [reason, setReason] = useState(detail?.moderator_message || '');
  const [status, setStatus] = useState(0);

  useEffect(() => {
    dispatch(claimActions.clearDetail());
  }, []);

  const onBootstrap = useCallback(() => {
    if (id) {
      dispatch(claimActions.fetchDetailAsync(+id));
    }
  }, [id]);

  useEffect(() => {
    onBootstrap();
  }, [onBootstrap]);

  const {
    control,
    formState: {errors},
    watch,
    handleSubmit,
    reset,
  } = useForm<Pick<Claim.ReqSendMail, 'note'>>({
    defaultValues: {
      note: detail?.note,
    },
  });

  useEffect(() => {
    if (detail) {
      reset({note: detail.note});
      setReason(detail.moderator_message || '');
    }
  }, [detail]);

  const onSubmit = (data: Pick<Claim.ReqSendMail, 'note'>) => {
    if (id) {
      dispatch(
        claimActions.sendMailAsync({
          id: +id,
          note: data.note,
          mail: true,
        }),
      );
    }
  };

  const note = watch('note');

  const onSaveNote = useCallback(() => {
    if (id) {
      dispatch(
        claimActions.sendMailAsync({
          id: +id,
          note: note,
          mail: false,
        }),
      );
    }
  }, [id, note]);

  const onChangeStatus = useCallback(() => {
    if (id) {
      dispatch(claimActions.updateItemAsync({id: +id, status}));
    }
  }, [detail, id, status]);

  const onUpdateReason = useCallback(() => {
    if (id && reason.trim()) {
      dispatch(
        claimActions.updateReasonAsync({
          id: +id,
          moderator_message: reason,
        }),
      );
    }
  }, [reason, id]);
  return {
    control,
    errors,
    handleSubmit: handleSubmit(onSubmit),
    detail,
    status,
    setStatus,
    onChangeStatus,
    isOpened,
    price,
    onSaveNote,
    isLoading,
    reason,
    setReason,
    onUpdateReason,
  };
};
