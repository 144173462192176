import {Moderator} from '.';

export enum types {
  FETCH_ITEMS = 'USER/FETCH_ITEMS',
  CREATE_ITEMS = 'USER/CREATE_ITEMS',
  REMOVE_ITEM = 'USER/REMOVE_ITEM',
}

export type ModeratorState = {
  items: Moderator.Item[];

  sorter: Moderator.Sorter;
};

//ASYNC

export type FetchItemsAsync = {
  type: typeof types.FETCH_ITEMS;
  payload: Moderator.ReqFetchItems;
};
export type CreateItemsAsync = {
  type: typeof types.CREATE_ITEMS;
  payload: Moderator.ReqCreateItems;
};

export type RemoveItemAsync = {
  type: typeof types.REMOVE_ITEM;
  payload: number;
};

export type ModeratorActionTypes =
  | FetchItemsAsync
  | CreateItemsAsync
  | RemoveItemAsync;
