import {FontWeight} from './../../themes/context';
import styled from 'styled-components';

type TProps = {
  isCurrent: boolean;
};

export const Wrapper = styled.div`
  padding-bottom: 32px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Scale = styled.div``;

export const Button = styled.button<TProps>`
  height: 36px;
  width: 36px;
`;

export const Form = styled.form``;
export const Input = styled.input`
  width: 100px;
  height: 36px;

  border: none;

  padding: 0 8px;
  outline: none;
`;
export const FormButton = styled.button`
  height: 36px;

  background-color: ${({theme, color}) => theme.button.background.default};
  color: ${({theme, color}) => theme.button.text.default};

  border: none;

  padding: 0 16px;
`;
export const Label = styled.label`
  & > span {
    font-weight: ${FontWeight.semiBold};
  }
`;
export const ContentWrapper = styled.div`
  margin-top: 8px;
`;
