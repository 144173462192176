import {object, string} from 'yup';

export const schema = object({
  email: string()
    .required('Введіть, будь-ласка, e-mail')
    .email('Некоректна email'),
  password: string()
    .required('Введіть, будь-ласка, пароль')
    .min(6, 'Пароль занадто короткий')
    .max(100, 'Пароль занадто довгий'),
});
