import {SagaIterator} from 'redux-saga';
import {all, call, put, take} from 'redux-saga/effects';
import {apiAuth} from '../../api';
import {authActions} from '../..';
import {types} from '../../types';

export function* logout(): SagaIterator {
  try {
    const response = yield call(apiAuth.logout);

    yield put(authActions.toggleLogined(false));
  } catch (e) {
    console.error(`error logout worker ${e}`);
  }
}
