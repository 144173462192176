import {apiModerator} from './../../api';
import {AxiosResponse} from 'axios';
import {uiActions} from 'src/bus/ui/slice';
import {all, call, put} from 'redux-saga/effects';
import {CreateItemsAsync} from '../../types';
import {moderatorActions} from '../../slice';

export function* createItems(action: CreateItemsAsync) {
  try {
    yield put(uiActions.toggleLoader({name: 'moderator', loading: true}));

    const response: AxiosResponse<any> = yield call(
      apiModerator.createItems,
      action.payload,
    );

    yield put(
      moderatorActions.fetchItemsAsync({
        sort: {
          key: 'full_name',
          type: 'asc',
        },
      }),
    );
  } catch (e) {
    console.log(`error create items worker ${e}`);
  } finally {
    yield put(uiActions.toggleLoader({name: 'moderator', loading: false}));
  }
}
