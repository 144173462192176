import {all, put, call, select} from 'redux-saga/effects';
import {uiActions} from 'src/bus/ui';
import {SagaIterator} from 'redux-saga';
import {types} from '../../types';
import {apiApp} from '../../api';
import {AxiosError, AxiosResponse} from 'axios';
import {App} from '../../namespace';
import {appActions} from '../../slice';
import {userActions} from 'src/bus/user';
import {authActions, authSelectors} from 'src/bus/auth';
import {showToast} from 'src/services/toast';

export function* fetchPrice(): SagaIterator {
  try {
    const response: AxiosResponse<App.ResFetchPrice> = yield call(
      apiApp.fetchPrice,
    );

    if (response.data) {
      yield all([
        put(appActions.updatePrice(response.data.access_price || 0)),
        put(
          userActions.saveDetail({
            is_admin: response.data.is_admin,
            full_name: response.data.full_name,
          }),
        ),
        put(authActions.toggleLogined(true)),
      ]);
    }
  } catch (e) {
    const err = e as AxiosError;

    if (err.response?.status === 401) {
      const loginned: boolean = yield select(authSelectors.getLogined);

      if (loginned) {
        yield put(authActions.toggleLogined(true));
        showToast({
          message: 'Ви не авторизовані',
          position: 'bottom-right',
          type: 'error',
          hideProgressBar: true,
        });
      }
    }

    console.log(`error fetch price worker ${e}`);
  } finally {
    yield put({type: types.END_FETCH_PRICE});
  }
}
