import {Auth, authActions} from 'src/bus/auth';
import {useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {schema} from './validate';
import {yupResolver} from '@hookform/resolvers/yup';
import {useCallback} from 'react';

export const useData = () => {
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    formState: {errors},
  } = useForm<Auth.ReqSignIn>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: Auth.ReqSignIn) => {
    dispatch(authActions.signInAsync(data));
  };
  const onLogout = useCallback(() => {
    dispatch(authActions.logoutAsync());
  }, []);
  return {
    control,
    errors,
    handleSubmit: handleSubmit(onSubmit),
    onLogout,
  };
};
