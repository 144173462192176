import React from 'react';
import {
  Wrapper,
  Table,
  Title,
  ContentWrapper,
  Header,
  Cell,
  CellContent,
  Label,
  Field,
  Settings,
  LoaderWrapper,
  NameLabel,
} from './AllClaims.styles';
import {ClaimCard} from 'src/components/Cards/ClaimCard';
import {useData} from './useData';
import {useNavigate} from 'react-router-dom';
import {Routes} from 'src/routes';
import {
  Button,
  Container,
  DateField,
  FilterForm,
  Loader,
  PaginateScale,
  SelectBox,
} from 'src/components';
import arrows from 'src/assets/images/arrow.png';

import ENV from 'src/configs';

export const AllClaims = () => {
  const {
    claims,
    page,
    onChangeSorter,
    sorter,
    per,
    onChangePer,
    onLoadByPage,
    onSelect,
    totlaPage,
    onChangeFilter,
    menu,
    isLoading,
    count,
    name,
  } = useData();

  const navigate = useNavigate();

  return (
    <>
      <Wrapper>
        <Container>
          <ContentWrapper isOpened={menu}>
            <Title>Усі заявки</Title>
            <Settings>
              <Field>
                <Label>Показувати по </Label>
                <SelectBox
                  onChange={({target}) => onChangePer(+target.value)}
                  width="80px"
                  height="32px">
                  <option selected={per === 5}>5</option>
                  <option selected={per === 10}>10</option>
                  <option selected={per === 25}>25</option>
                  <option selected={per === 50}>50</option>
                  <option selected={per === 100}>100</option>
                  <option selected={per === 250}>250</option>
                  <option selected={per === 500}>500</option>
                </SelectBox>
              </Field>
              <NameLabel>
                <span>
                  <b>Модератор:</b> {name}
                </span>
                <Button
                  onClick={() =>
                    window.open(`${ENV.BASE_URL}/statistics/file_all`)
                  }>
                  Завантажити реєстр
                </Button>
              </NameLabel>
            </Settings>
            <FilterForm onSubmit={onChangeFilter} />

            <Table>
              <Header>
                <Cell role="button" onClick={() => onChangeSorter('id')}>
                  <CellContent>
                    ID
                    <img src={arrows} />
                  </CellContent>
                </Cell>
                <Cell
                  role="button"
                  onClick={() => onChangeSorter('created_at')}>
                  <CellContent>
                    Дата, час
                    <img src={arrows} />
                  </CellContent>
                </Cell>
                <Cell role="button" onClick={() => onChangeSorter('full_name')}>
                  <CellContent>
                    Піб особи
                    <img src={arrows} />
                  </CellContent>
                </Cell>
                <Cell role="button" onClick={() => onChangeSorter('ipn')}>
                  <CellContent>
                    ІНН
                    <img src={arrows} />
                  </CellContent>
                </Cell>
                <Cell role="button" onClick={() => onChangeSorter('certificate_participant_hostilities')}>
                  <CellContent>
                    УБД
                    <img src={arrows} />
                  </CellContent>
                </Cell>
                <Cell role="button" onClick={() => onChangeSorter('status')}>
                  <CellContent>
                    Статус
                    <img src={arrows} />
                  </CellContent>
                </Cell>
                <Cell
                  role="button"
                  onClick={() => onChangeSorter('email_sent')}>
                  <CellContent>
                    Лист
                    <img src={arrows} />
                  </CellContent>
                </Cell>
                <Cell
                  role="button"
                  onClick={() => onChangeSorter('moderator_full_name')}>
                  <CellContent>
                    Модератор
                    <img src={arrows} />
                  </CellContent>
                </Cell>
              </Header>
              {!isLoading ? (
                claims.map((claim, index) => (
                  <ClaimCard
                    onSearch={onChangeFilter}
                    onSelect={() => onSelect(claim.id, !claim.selected)}
                    isGray={index % 2 === 1}
                    key={`claim-${claim.id}`}
                    claim={claim}
                  />
                ))
              ) : (
                <LoaderWrapper>
                  <Loader size="100px" />
                </LoaderWrapper>
              )}
            </Table>
            <PaginateScale
              count={count}
              current={page}
              onClick={onLoadByPage}
              total_page={totlaPage}
              per={per}
              length={claims.length}
            />
          </ContentWrapper>
        </Container>
      </Wrapper>
    </>
  );
};
