import {AxiosResponse} from 'axios';
import {all, put, call} from 'redux-saga/effects';
import {uiActions} from 'src/bus/ui';
import {SagaIterator} from 'redux-saga';
import {
  FetchDetailAsync,
  FetchItemsAsync,
  FetchPreviewItemAsync,
  types,
} from '../../types';
import {apiClaim} from '../../api';
import {claimActions} from '../../slice';
import {Claim} from '../../namespace';

export function* fetchItems(action: FetchItemsAsync): SagaIterator {
  try {
    yield put(uiActions.toggleLoader({name: 'claim', loading: true}));

    if (action.payload.page === 1) {
      yield put(claimActions.clearItems());
    }

    const response: AxiosResponse<Claim.ResFetchItems> = yield call(
      apiClaim.fetchItems,
      action.payload,
    );

    if (response.data) {
      yield put(claimActions.saveItems(response.data));
    }
  } catch (e) {
    console.log(`error fetch claim items worker ${e}`);
  } finally {
    yield all([
      put(uiActions.toggleLoader({name: 'claim', loading: false})),
      put({type: types.END_FETCH_ITEM}),
    ]);
  }
}

export function* fetchDetail(action: FetchDetailAsync): SagaIterator {
  try {
    yield put(uiActions.toggleLoader({name: 'claim', loading: true}));

    const response: AxiosResponse<Claim.ResFetchDeail> = yield call(
      apiClaim.fetchDetail,
      action.payload,
    );

    yield put(claimActions.saveDetail(response.data));
  } catch (e) {
    console.log(`error fetch claim detail ${e}`);
  } finally {
    yield all([
      put(uiActions.toggleLoader({name: 'claim', loading: false})),
      put({type: types.END_FETCH_DETAIL}),
    ]);
  }
}

export function* fetchPreviewItem(action: FetchPreviewItemAsync): SagaIterator {
  try {
    yield put(uiActions.toggleLoader({name: 'claim', loading: true}));

    const response: AxiosResponse<Claim.Detail> = yield call(
      apiClaim.fetchPreviewItem,
      action.payload,
    );

    if (response.data) {
      yield put(claimActions.saveDetail(response.data));
    }
  } catch (e) {
    console.log(`error fetch preview item ${e} worker`);
  } finally {
    yield put(uiActions.toggleLoader({name: 'claim', loading: false}));
  }
}
