import {useCallback, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {appSelectors} from 'src/bus/app';
import {Claim, claimSelectors} from 'src/bus/claim';

export const useData = () => {
  const price = useSelector(appSelectors.getPrice);

  const filter = useSelector(claimSelectors.getFilter);

  const {control, handleSubmit, reset} = useForm<Claim.Filter>({
    defaultValues: {
      category: null,
      created_at: null,
      email_sent: null,
      full_name: null,
      id: null,
      ipn_passport: null,
      moderator_full_name: null,
      status: null,
      certificate_participant_hostilities: null
    },
  });

  const onReset = useCallback(() => {
    reset({
      category: null,
      created_at: null,
      email_sent: null,
      full_name: null,
      id: null,
      ipn_passport: null,
      moderator_full_name: null,
      status: null,
      certificate_participant_hostilities: null
    });
  }, []);

  useEffect(() => {
    if (filter) {
      reset({
        category: filter.category,
        created_at: filter.created_at,
        email_sent: filter.email_sent,
        full_name: filter.full_name,
        id: filter.id,
        ipn_passport: filter.ipn_passport,
        moderator_full_name: filter.moderator_full_name,
        status: filter.status,
        certificate_participant_hostilities: filter.certificate_participant_hostilities
      });
    }
  }, [filter]);

  return {
    control,
    handleSubmit,
    onReset,
    price,
  };
};
