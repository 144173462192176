import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Auth} from './namespace';

import {AuthActionTypes, AuthState, types} from './types';

const initialState: AuthState = {
  logined: false,
};

const slice = createSlice({
  name: 'Auth',
  initialState,
  reducers: {
    toggleLogined: (state: AuthState, action: PayloadAction<boolean>) => {
      state.logined = action.payload;
    },
  },
});

export default slice.reducer;

export const authActions = {
  ...slice.actions,
  //ASYNC
  signInAsync: (payload: Auth.ReqSignIn): AuthActionTypes => ({
    type: types.SIGN_IN,
    payload,
  }),

  logoutAsync: (): AuthActionTypes => ({
    type: types.LOGOUT,
  }),
};
