import React, {FC, useCallback, useMemo, useRef} from 'react';
import {Controller} from 'react-hook-form';
import {useLocation} from 'react-router-dom';
import {Claim} from 'src/bus/claim';
import {Routes} from 'src/routes';
import {Button, DateField, FilledField, SelectBox} from '../../Core';

import {Field, Label, Price, Wrapper} from './FilterForm.styles';
import {useData} from './useData';

type TProps = {
  onSubmit: (filter: Claim.Filter | null) => any;
};

const STATUSES = [
  'Без статусу',
  'Неповний пакет документів',
  'Нецільова аудиторія',
  'Документи прийняті, кошти надіслані',
  'Особа у черзі, очікуються додаткові кошти',
];

const NAMES = ['started', 'uncorrect', 'unright', 'supported', 'waited'];

export const FilterForm: FC<TProps> = ({onSubmit}) => {
  const {control, handleSubmit, onReset, price} = useData();

  const {pathname} = useLocation();

  const renderStatuses = useCallback((value: string | null) => {
    return STATUSES.map((status, index) => (
      <option
        selected={value ? value === NAMES[index] : false}
        value={NAMES[index]}>
        {status}
      </option>
    ));
  }, []);

  const isAll = useMemo(() => pathname === Routes.ALL_CLAIMS, [pathname]);

  return (
    <>
      <Wrapper onSubmit={handleSubmit(onSubmit)}>
        <Field>
          <Label>ID</Label>
          <Controller
            control={control}
            name="id"
            render={({field: {name, onBlur, onChange, value}}) => (
              <FilledField
                name={name}
                onChange={onChange}
                value={value || ''}
                onBlur={onBlur}
                width="100%"
              />
            )}
          />
        </Field>

        <Field>
          <Label>Дата реєстрації</Label>
          <Controller
            control={control}
            name="created_at"
            render={({field: {name, onBlur, onChange, value}}) => (
              <DateField
                name={name}
                onChange={onChange}
                value={value || ''}
                onBlur={onBlur}
                width="100%"
                max="4000-12-01"
              />
            )}
          />
        </Field>

        <Field>
          <Label>ПІБ</Label>
          <Controller
            control={control}
            name="full_name"
            render={({field: {name, onBlur, onChange, value}}) => (
              <FilledField
                name={name}
                onChange={onChange}
                value={value || ''}
                onBlur={onBlur}
                width="100%"
              />
            )}
          />
        </Field>

        <Field>
          <Label>ІНН</Label>
          <Controller
            control={control}
            name="ipn_passport"
            render={({field: {name, onBlur, onChange, value}}) => (
              <FilledField
                name={name}
                onChange={onChange}
                value={value || ''}
                onBlur={onBlur}
                width="100%"
              />
            )}
          />
        </Field>

        {/* <Field>
          <Label>Категорія</Label>
          <Controller
            control={control}
            name="category"
            render={({field: {name, onBlur, onChange, value}}) => (
              <SelectBox
                width="100%"
                name={name}
                onChange={(e) => {
                  onChange({
                    ...e,
                    target: {...e.target, value: !!+e.target.value},
                  });
                }}
                onBlur={onBlur}>
                <option hidden selected={value === null}>
                  Оберіть категорію
                </option>
                <option value={1} selected={value ? +value === 1 : false}>
                  A
                </option>
                <option value={0} selected={value ? +value === 0 : false}>
                  Б
                </option>
              </SelectBox>
            )}
          />
        </Field> */}

        <Field>
          <Label>УБД</Label>
          <Controller
            control={control}
            name="certificate_participant_hostilities"
            render={({field: {name, onBlur, onChange, value}}) => (
              <FilledField
                name={name}
                onChange={onChange}
                value={value || ''}
                onBlur={onBlur}
                width="100%"
              />
            )}
          />
        </Field>

        {isAll && (
          <Field>
            <Label>Статус</Label>
            <Controller
              control={control}
              name="status"
              render={({field: {name, onBlur, onChange, value}}) => (
                <SelectBox
                  width="100%"
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}>
                  <option hidden selected={value === null}>
                    Оберіть статус заявки
                  </option>
                  {renderStatuses(value)}
                </SelectBox>
              )}
            />
          </Field>
        )}

        <Field>
          <Label>Лист</Label>
          <Controller
            control={control}
            name="email_sent"
            render={({field: {name, onBlur, onChange, value}}) => (
              <SelectBox
                width="100%"
                name={name}
                onChange={(e) =>
                  onChange({
                    ...e,
                    target: {...e.target, value: !!+e.target.value},
                  })
                }
                onBlur={onBlur}>
                <option hidden selected={value === null}>
                  Оберіть статус листа
                </option>
                <option value={1} selected={value ? +value === 1 : false}>
                  Відправлено
                </option>
                <option value={0} selected={value ? +value === 0 : false}>
                  Не відправлено
                </option>
              </SelectBox>
            )}
          />
        </Field>

        <Field>
          <Label>Модератор</Label>
          <Controller
            control={control}
            name="moderator_full_name"
            render={({field: {name, onBlur, onChange, value}}) => (
              <FilledField
                name={name}
                onChange={onChange}
                value={value || ''}
                onBlur={onBlur}
                width="100%"
              />
            )}
          />
        </Field>

        

        <Button width="100%" margin={{top: isAll ? '0' : '7%'}}>
          Фільтрувати
        </Button>
        <Button
          type="button"
          onClick={() => {
            onReset();
            onSubmit(null);
          }}
          width="100%">
          Очистити
        </Button>

        <Price>
          Можна опрацювати заявок на суму: <span>{price}</span> грн
        </Price>
      </Wrapper>
    </>
  );
};
