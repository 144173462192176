import {FC, useCallback, useRef, useState} from 'react';
import {Claim, claimSelectors} from 'src/bus/claim';

import {Cell, Wrapper, CellContent, CheckboxWrapper} from './ClaimCard.styles';

import {format} from 'date-fns';
import {uk} from 'date-fns/locale';
import {Checkbox} from 'src/components/Core';
import {Link} from 'react-router-dom';
import {Routes} from 'src/routes';
import {useSelector} from 'react-redux';
type TProps = {
  onSelect: () => any;
  onSearch: (filter: Claim.Filter) => any;

  claim: Claim.Item;
  isGray: boolean;
};

export const ClaimCard: FC<TProps> = ({onSelect, claim, isGray, onSearch}) => {
  const ref = useRef(null);

  const filter = useSelector(claimSelectors.getFilter);

  return (
    <Wrapper>
      <Cell isGray={isGray}>
        <Link to={`${Routes.CLAIM_DETAIL}/${claim.id}`} target="_blank">
          <CellContent>{claim.id}</CellContent>
        </Link>
      </Cell>
      <Cell isGray={isGray}>
        <CellContent>
          {format(new Date(claim.created_at), 'kk:mm:ss', {
            locale: uk,
          })}
          <span>
            {format(new Date(claim.created_at), 'dd.LL.yyyy', {
              locale: uk,
            })}
          </span>
        </CellContent>
      </Cell>
      <Cell isGray={isGray}>
        <CellContent>{claim.full_name}</CellContent>
      </Cell>
      <Cell isGray={isGray}>
        <CellContent
          role="button"
          pointer
          onClick={() => {
            if (claim.ipn) {
              onSearch(
                filter
                  ? {
                      ...filter,
                      ipn_passport: `${claim.ipn}`,
                    }
                  : {
                      ipn_passport: `${claim.ipn}`,
                      category: null,
                      created_at: null,
                      full_name: null,
                      email_sent: null,
                      id: null,
                      moderator_full_name: null,
                      status: null,
                      certificate_participant_hostilities: null
                    },
              );
            }
          }}>
          {claim.ipn}{' '}
          {claim.ipn ? `(${claim.ipn_count})` || '(0)' : claim.passport}
        </CellContent>
      </Cell>
      <Cell isGray={isGray}>
        <CellContent role="button"
          pointer
          onClick={() =>{
            if(claim.certificate_participant_hostilities){
              onSearch(
                filter
                  ? {
                      ...filter,
                      certificate_participant_hostilities:`${claim.certificate_participant_hostilities}`
                    }
                  : {
                      ipn_passport: null,
                      category: null,
                      created_at: null,
                      full_name: null,
                      email_sent: null,
                      id: null,
                      moderator_full_name: null,
                      status: null,
                      certificate_participant_hostilities:`${claim.certificate_participant_hostilities}`
                    },
              );
            }
          }}>{claim.certificate_participant_hostilities ? `${claim.certificate_participant_hostilities} (${claim.certificate_counter || 0})` : '-'}</CellContent>
      </Cell>

      <Cell isGray={isGray}>
        <CellContent>{claim.status}</CellContent>
      </Cell>
      <Cell isGray={isGray}>
        <CellContent>
          {claim.email_sent ? 'Відправлено' : 'Не відправлено'}
        </CellContent>
      </Cell>
      <Cell isGray={isGray}>
        <CellContent ref={ref}>
          {claim.moderator_full_name}
          <CheckboxWrapper>
            <Checkbox
              value={claim.selected}
              onChange={() => {
                if (claim.is_u || !claim.selected) {
                  onSelect();
                }
              }}
            />
          </CheckboxWrapper>
        </CellContent>
      </Cell>
    </Wrapper>
  );
};
