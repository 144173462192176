import {all, put, call, take} from 'redux-saga/effects';
import {uiActions} from 'src/bus/ui';
import {SagaIterator} from 'redux-saga';
import {types, UpdateItemAsync, UpdateReasonAsync} from '../../types';
import {apiClaim} from '../../api';
import {AxiosError, AxiosResponse} from 'axios';
import {Claim} from '../../namespace';
import {claimActions} from '../../slice';
import {appActions} from 'src/bus/app';

export function* updateItem(action: UpdateItemAsync): SagaIterator {
  try {
    yield put(uiActions.toggleLoader({name: 'claim', loading: true}));

    const response: AxiosResponse<Claim.ResUpdateItem> = yield call(
      apiClaim.updateItem,
      action.payload,
    );

    if (response.data) {
      yield all([
        put(claimActions.updateItem(action.payload)),
        put(claimActions.fetchDetailAsync(action.payload.id)),
      ]);

      if (action.payload.status === 3) {
        yield put(appActions.fetchPriceAsync());
      }

      yield take(types.END_FETCH_DETAIL);
    }
  } catch (e) {
    const error = e as AxiosError;

    console.log(`error update claim item worker ${e}`);

    if (error.response?.status === 400) {
      yield put(appActions.updatePrice(0));
    }
  } finally {
    yield put(uiActions.toggleLoader({name: 'claim', loading: false}));
  }
}

export function* updateReason(action: UpdateReasonAsync): SagaIterator {
  try {
    yield put(uiActions.toggleLoader({name: 'claim', loading: true}));

    const response: AxiosResponse<Claim.ResUpdateItem> = yield call(
      apiClaim.updateReason,
      action.payload,
    );

    yield put(claimActions.fetchDetailAsync(action.payload.id));

    yield take(types.END_FETCH_DETAIL);
  } catch (e) {
    const error = e as AxiosError;

    console.log(`error update claim item worker ${e}`);
  } finally {
    yield put(uiActions.toggleLoader({name: 'claim', loading: false}));
  }
}
