import {combineReducers} from '@reduxjs/toolkit';

//types
import {AppState} from 'src/bus/app/types';
import {AuthState} from 'src/bus/auth/types';
import {LocationState} from 'src/bus/location/types';
import {UserState} from 'src/bus/user/types';
// import {ThemesState} from '/bus/themes/types';
import {UiState} from 'src/bus/ui/types';
import {ClaimState} from 'src/bus/claim/types';
import {ModeratorState} from 'src/bus/moderator/types';
import {StatisticState} from 'src/bus/statistic/types';

//reducers
import {appReducer} from 'src/bus/app';
import {authReducer} from 'src/bus/auth';
import {userReducer} from 'src/bus/user';
import {moderatorReducer} from 'src/bus/moderator';
// import {themesReducer} from '/bus/themes';
import {uiReducer} from 'src/bus/ui';
import {locationReducer} from 'src/bus/location';
import {claimReducer} from 'src/bus/claim';
import {statisticReducer} from 'src/bus/statistic';

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  user: userReducer,
  // themes: themesReducer,
  ui: uiReducer,
  location: locationReducer,
  claim: claimReducer,
  moderator: moderatorReducer,
  statistic: statisticReducer,
});

export type RootState = {
  app: AppState;
  user: UserState;
  // themes: ThemesState;
  auth: AuthState;
  ui: UiState;
  location: LocationState;
  claim: ClaimState;
  moderator: ModeratorState;
  statistic: StatisticState;
};

export default rootReducer;
