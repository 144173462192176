import axios, {AxiosError, AxiosInstance} from 'axios';
import {authActions} from 'src/bus/auth';
import ENV from 'src/configs';
import {Routes} from 'src/routes';
import {store} from 'src/store';
import {showToast} from './toast';

const axiosInstance: AxiosInstance = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    credentials: 'include',
  },
  timeout: 120000,
  baseURL: `${ENV.BASE_URL}`,
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = await localStorage.getItem('TOKEN');

    if (token && config.headers) {
      config.headers['Authorization'] = `${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error: AxiosError) {
    if (error.response?.status === 401) {
      if (error.config.url !== '/costs/today') {
        store.dispatch(authActions.toggleLogined(false));

        showToast({
          message: 'Ви не авторизовані',
          position: 'bottom-right',
          type: 'error',
          hideProgressBar: true,
        });
      }
    } else {
      if (error.response?.status && error.response.status > 499) {
        showToast({
          message: 'Внутрішня помилка сервера',
          position: 'bottom-right',
          type: 'error',
          hideProgressBar: true,
        });
      } else {
        if (error.response?.data.errors) {
          showToast({
            message: error.response?.data.errors.joint(', '),
            position: 'bottom-right',
            type: 'error',
            hideProgressBar: true,
          });
        } else {
          showToast({
            message: error.response?.data.error,
            position: 'bottom-right',
            type: 'error',
            hideProgressBar: true,
          });
        }
      }
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
