import {FontWeight} from 'src/themes';
import styled from 'styled-components';

type TProps = {
  isCurrent?: boolean;
};
type TWrapperProps = {
  isOpened?: boolean;
};

type TBurgerProps = {
  isOpened?: boolean;
};

export const List = styled.ul`
  list-style: none;
  padding-left: 0;

  overflow-y: scroll;

  height: 40vh;

  overflow-x: hidden;
`;

export const Wrapper = styled.div<TWrapperProps>`
  width: 300px;
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;

  transform: translateX(${({isOpened}) => (isOpened ? 0 : '-100%')});

  /* overflow-y: scroll; */
  transition: 0.5s all;

  bottom: 0;
  top: 0;
  z-index: 2;

  background-color: ${({theme}) => theme.background.light};
`;
export const Logo = styled.img`
  margin: 20px 0 40px 0;
  width: 100%;
`;
export const Content = styled.div`
  width: 100%;
  padding: 0 64px 0 20%;

  overflow-x: hidden;
`;
export const Title = styled.h1`
  padding: 32px 0;
  font-size: 29px;
  color: ${({theme}) => theme.text.gray};
  font-weight: ${FontWeight.light};

  border-bottom: 1px solid ${({theme}) => theme.line.default};
  border-top: 1px solid ${({theme}) => theme.line.default};
`;
export const Image = styled.img`
  margin-right: 14px;
`;
export const Item = styled.li<TProps>`
  padding: 20px 0;
  & > a {
    color: ${({theme}) => theme.text.default};
    text-decoration: none;

    margin-left: 8px;
  }

  display: flex;
  align-items: center;

  transition: 0.3s all;
  transform: translateX(${({isCurrent = false}) => (isCurrent ? `30px` : '')});
  &:hover {
    transform: translateX(30px);
  }
  cursor: pointer;
`;

export const Burger = styled.button<TBurgerProps>`
  position: absolute;

  right: 8px;
  top: 16px;
  transform: translateX(${({isOpened}) => (isOpened ? 0 : '180%')});
  transition-delay: 1s;
  transition: 0.3s all;

  padding: 0;

  z-index: 100;

  height: 20px;
  width: 28px;

  border: none;

  display: flex;
  flex-direction: column;

  justify-content: space-between;

  background-color: transparent;

  cursor: pointer;

  & div {
    height: 2px;
    width: 100%;

    background-color: ${({theme}) => theme.background.dark};

    transition: 0.3s all;

    &:nth-child(1) {
      transform: rotate(${({isOpened}) => (isOpened ? '-45deg' : '0')});

      ${({isOpened}) =>
        isOpened &&
        `
      position: absolute;
      top: 9px;
      

      `}
    }
    &:nth-child(2) {
      opacity: ${({isOpened}) => (isOpened ? '0' : '1')};
    }
    &:nth-child(3) {
      ${({isOpened}) =>
        isOpened &&
        `
      position: absolute;
      bottom:9px;
      

      `}

      transform: rotate(${({isOpened}) => (isOpened ? '45deg' : '0')});
    }
  }
`;

export const Overlay = styled.div`
  position: fixed;

  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;
