import {SendMailAsync, types} from './../../types';
import {all, put, call, take} from 'redux-saga/effects';
import {uiActions} from 'src/bus/ui';
import {SagaIterator} from 'redux-saga';
import {apiClaim} from '../../api';
import {claimActions} from '../../slice';

export function* sendMail(action: SendMailAsync): SagaIterator {
  try {
    yield put(uiActions.toggleLoader({name: 'mail', loading: true}));

    const response = yield call(apiClaim.sendMail, action.payload);

    yield put(claimActions.fetchDetailAsync(action.payload.id));

    yield take(types.END_FETCH_DETAIL);
  } catch (e) {
    console.log(`error send mail worker ${e}`);
  } finally {
    yield put(uiActions.toggleLoader({name: 'mail', loading: false}));
  }
}
