import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Claim, claimActions, claimSelectors} from 'src/bus/claim';
import {uiSelectors} from 'src/bus/ui';

export const useData = () => {
  const dispatch = useDispatch();

  const detail = useSelector(claimSelectors.getDetail);

  const isLoading = useSelector(uiSelectors.getLoading('claim'));

  const {id} = useParams();

  const onBootstrap = useCallback(() => {
    if (id) {
      dispatch(claimActions.fetchDetailAsync(+id));
    }
  }, [id]);

  useEffect(() => {
    onBootstrap();
  }, [onBootstrap]);

  useEffect(() => {
    if (detail) {
      setTimeout(() => {
        window.print();
      }, 200);
    }
  }, [detail]);

  return {detail, isLoading};
};
