import {object, string} from 'yup';

export const schema = object({
  email: string().required('Поле email пусте').email('Некоректна пошта'),
  full_name: string()
    .required('Пропущене прізвище, ім`я або по батькові')
    .matches(
      /^[А-ЯІЄЇҐ][а-яА-ЯІЄЇҐієїґ\-\`\'\ʼ\']*\s[А-ЯІЄЇҐ][а-яА-ЯІЄЇҐієїґ\-\`\'\ʼ\']+(\s[А-ЯІЄЇҐ][а-яА-ЯІЄЇҐієїґ\-\`\'\ʼ\']+)?$/g,
      'Пропущене прізвище, ім`я або по батькові',
    ),
  password: string()
    .required('Поле пароль пусте')
    .min(6, 'Короткий')
    .max(100, 'Довгий'),
});
