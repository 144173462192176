import {uiActions} from 'src/bus/ui';
import {AxiosResponse} from 'axios';
import {SagaIterator} from 'redux-saga';
import {all, call, put} from 'redux-saga/effects';
import {Moderator, moderatorActions} from '../..';
import {apiModerator} from '../../api';
import {FetchItemsAsync, types} from '../../types';
// import {authActions} from 'src/bus/auth';

export function* fetchItems(action: FetchItemsAsync): SagaIterator {
  try {
    yield put(uiActions.toggleLoader({name: 'moderator', loading: true}));

    const response: AxiosResponse<Moderator.ResFetchItems> = yield call(
      apiModerator.fetchItems,
      action.payload,
    );

    if (response.data) {
      yield put(moderatorActions.saveItems(response.data));
    }
  } catch (e) {
    console.error(`error fetch user detail ${e}`);
  } finally {
    yield all([
      put(uiActions.toggleLoader({name: 'moderator', loading: false})),
    ]);
  }
}
