import {AxiosPromise} from 'axios';
import axios from 'src/services/axios';
import {Auth} from './namespace';

export const apiAuth = new (class Api {
  signIn(data: Auth.ReqSignIn): AxiosPromise {
    return axios({
      url: '/users/sign_in',
      method: 'post',
      data: {
        user: data,
      },
    });
  }
  logout(): AxiosPromise {
    return axios({
      url: '/users/sign_out',
      method: 'delete',
    });
  }
})();
