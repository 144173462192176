import styled from 'styled-components';

type TProps = {
  isGray: boolean;
};

export const Wrapper = styled.li`
  width: 100%;

  display: grid;
  grid-template-columns: 20fr 10fr;
`;

export const Cell = styled.div<TProps>`
  min-height: 80px;
  width: 100%;

  /* border: 1px solid red; */
  border-right: 1px solid ${({theme}) => theme.border.gray};
  border-bottom: 1px solid ${({theme}) => theme.border.gray};

  background-color: ${({isGray, theme}) =>
    isGray ? theme.background.gray : theme.background.default};

  :first-child {
    border-left: 1px solid ${({theme}) => theme.border.gray};
  }

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
`;
export const CellContent = styled.div``;
