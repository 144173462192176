import {FontWeight} from 'src/themes';
import styled from 'styled-components';

export const Wrapper = styled.form`
  padding: 16px;

  background-color: ${({theme}) => theme.background.light};

  display: grid;

  grid-template-columns: 1fr 1fr 1fr 1fr;

  column-gap: 8px;

  margin-bottom: 16px;
`;

export const Label = styled.label``;
export const Field = styled.div`
  width: 100%;
`;

export const Price = styled.div`
  grid-column-start: 3;
  grid-column-end: 5;

  padding-left: 8px;

  display: flex;
  align-items: center;

  & > span {
    font-weight: ${FontWeight.medium};
    margin: 0 6px;
  }
`;
