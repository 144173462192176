import {FontWeight} from 'src/themes';
import styled from 'styled-components';

type TProps = {
  isGray: boolean;
};

type TPropsCellContent = {
  pointer?: boolean;
};

export const Wrapper = styled.li`
  display: grid;
  grid-template-columns: 6vw 11vw 14vw 9vw 8vw 9vw 9vw 13vw;
  width: 100%;
  margin-right: 0;

  width: 100%;
  font-size: 14px;

  ${({theme}) => theme.mediaQueriesMax.md} {
    grid-template-columns: 8vw 13vw 16vw 11vw 10vw 11vw 11vw 15vw;
  }
  ${({theme}) => theme.mediaQueriesMax.sm} {
    font-size: 9px;
  }
`;
export const Cell = styled.div<TProps>`
  min-height: 110px;
  width: 100%;

  /* border: 1px solid red; */
  border-right: 1px solid ${({theme}) => theme.border.gray};
  border-bottom: 1px solid ${({theme}) => theme.border.gray};

  background-color: ${({isGray, theme}) =>
    isGray ? theme.background.gray : theme.background.default};

  :first-child {
    border-left: 1px solid ${({theme}) => theme.border.gray};
  }

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  ${({theme}) => theme.mediaQueriesMax.sm} {
    min-height: 60px;
  }
`;
export const CellContent = styled.div<TPropsCellContent>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  cursor: ${({pointer}) => (pointer ? 'pointer' : 'auto')};

  text-align: center;
  & > span {
    font-weight: ${FontWeight.medium};
  }
`;

export const CheckboxWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`;
